import React from 'react';
import { apiClient } from '../../Helpers/apiCall';
import { useStore } from '../../../store/useStore';
import Loader from './Loader';
import { messaging } from '../../../init-fcm';
import { toast } from 'react-toastify';

export default function RequestPermissionToggle({ loaderClass }) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	const { user } = state;

	const hasNotification = 'Notification' in window;

	const hasPermission =
		hasNotification &&
		!!(hasNotification && Notification.permission === 'granted') &&
		(user?.data?.notification_settings ?? false);

	// eslint-disable-next-line eqeqeq
	const permissionsBlocked = hasNotification && Notification.permission == 'denied';

	const togglePermission = () => {
		dispatch({ type: 'CHANGE_NOTIFICATION_SETTINGS' });
		if (hasPermission) {
			clearNotification();
		} else {
			requestNotificationPermission();
		}
	};

	const clearNotification = async () => {
		saveNotificationSettings('');
		try {
			await messaging.deleteToken();
		} catch (error) {
			console.log(error);
		}
	};

	const saveNotificationSettings = (token) => {

		const currentTokens = [...(user.data.fcmtoken ?? [])];

		apiCall
			.EDIT('users', user.data.id, {
				notification_settings: token,
				fcmtoken: token.length > 0 ? [...currentTokens, { token: token }] : currentTokens,
			})
			.then((response) => {
				if (response.status === 200) {
					// apiCall.GET_USER(user.data.id);
					dispatch({ type: 'USER_REFRESH', payload: response });
					dispatch({ type: 'CHANGE_NOTIFICATION_SETTINGS_SUCCESS' });
				}
			});
	};

	const requestNotificationPermission = async function () {
		if (permissionsBlocked) {
			toast.error(
				'Notifications blocked, check your device settings and try again'
			);
		}

		if (hasNotification) {
			Notification.requestPermission()
				.then(async function () {
					const token = await messaging.getToken();
					console.log('Got permission to notify.', token);
					saveNotificationSettings(token);
				})
				.catch(function (err) {
					console.warn('Unable to get permission to notify.', err);
					dispatch({ type: 'CHANGE_NOTIFICATION_SETTINGS_FAIL' });
				});
		} else {
			toast.error('You device does not support notifications');
			dispatch({ type: 'CHANGE_NOTIFICATION_SETTINGS_FAIL' });
		}
	};

	return (
		<div className='c-requestpermission'>
			<label className="c-switch">
				<input
					type="checkbox"
					onClick={togglePermission}
					checked={hasPermission}
					readOnly
					disabled={user.notificationsLoading}
				/>
				<span></span>
				{hasPermission && `Notifications enabled`}
				{!permissionsBlocked && !hasPermission && `Allow notifications`}
				{permissionsBlocked && `⚠️ Notifications blocked!`}
			</label>
			{user.notificationsLoading && hasNotification && (
				<div>
					<label>
						<Loader className={loaderClass} />
					</label>
				</div>
			)}
		</div>
	);
}
