import React, { useState, useRef } from 'react';

import RequestPermissionToggle from '../Components/common/RequestPermissionToggle';

import TinySlider from 'tiny-slider-react';
// import 'tiny-slider/dist/tiny-slider.css';
// import AppHeader from '../Components/AppHeader';

export const Carousel = ({ settings, endOnboarding, setIndex }) => {
	const onChange = (info, eventName) => {

		// setIndex(info.index);

		if (info.index === 7) {
			endOnboarding();
		}
	};

	const onSlideChanged = (info) => {
		setIndex(info.index);
	}

	const ts = useRef(null);
	const onGoTo = (dir) => ts.current.slider.goTo(dir);
	// const rosterSvg = useRef(null);

	// useEffect(() => {
	// 	const intervalId = setInterval(() => {
			
	// 	}, 1000);

	// 	return () => {
	// 		clearInterval(intervalId);
	// 	};
	// });

	return (
		<div className='carousel'>
			<TinySlider
				settings={settings}
				onTransitionEnd={onChange}
				onTransitionStart={onSlideChanged}
				ref={ts}
			>
				<div className='slide'>
					<div className='slide-content'>
						<div className='icon desktop'></div>
						<h4>HOME SCREEN</h4>
						<p>
							Add Royal Backstage to your phone’s home screen for quick access.
						</p>
						<button className='c-button' onClick={() => onGoTo('next')}>Ok</button>
					</div>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon notifications'></div>
						<h4>NOTIFICATIONS</h4>
						<p>
							Allow notifications so you notice important messages,
							announcements, and inquiries.
						</p>
						<RequestPermissionToggle />
					</div>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon toolkit'></div>
						<h4>Toolkit</h4>
						<p>
							Toolkit includes instructions, rules and shared guidelines. The
							content is continuously updated.
						</p>
						<button className='c-button' onClick={() => onGoTo('next')}>Ok</button>
					</div>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon roster'></div>
						<h4>Roster</h4>
						<p>Contact information conveniently in one place.</p>
					</div>
					<button className='c-button' onClick={() => onGoTo('next')}>Ok</button>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon feedback'></div>
						<h4>Feedback</h4>
						<p>Provide anonymous feedback on any matter that’s on your mind.<br />{' '}</p>
					</div>
					<button className='c-button' onClick={() => onGoTo('next')}>Ok</button>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon weekly'></div>
						<h4>Queendom's weekly</h4>
						<p>
							Weekly newsletter featuring updates and accomplishments of staff
							and customers.
						</p>
						<button className='c-button' onClick={() => onGoTo('next')}>Ok</button>
					</div>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon applauds'></div>
						<h4>Applauds</h4>
						<p>
							Anonymous platform for giving compliments - compliment a colleague
							and receive compliments in return.
						</p>
						<button className='c-button' onClick={() => onGoTo('next')}>Ok</button>
					</div>
				</div>

				<div className='slide'>
					<div className='slide-content'>
						<div className='icon done'></div>
						<h4>That's it, all done!</h4>
						<p>Welcome to the Royal Backstage!</p>
					</div>
				</div>
				{/* <div className="carousel-nav">• • •</div> */}
			</TinySlider>
		</div>
	);
};

const OnBoarding = ({ endOnboarding, user }) => {

	const newsettings = {
		lazyload: false,
		loop: false,
		nav: true,
		controls: false,
		controlsText: ['‹', '›'],
		swipeAngle: 25,
		arrowKeys: false,
		mouseDrag: true,
		// speed: 1000,
		autoPlay: false,
		autoplayHoverPause: true,
		navPosition: 'bottom',
	};

	const [index, setIndex] = useState(0);

	return (
		<div className='c-onboarding' data-index={index}>
			{/* <PulsingCircles /> */}
			<div className=''>
				{/* <div className="c-page-header">
					<h1>Hi {user.first_name}!</h1>
				</div> */}
				<Carousel settings={newsettings} endOnboarding={endOnboarding} setIndex={setIndex} />
				<button className='skip' onClick={endOnboarding}>
					{'skip ->'}
				</button>
			</div>
		</div>
	);
};
/* 
const PulsingCircles = () => {
    const rosterSvg = useRef(null);
		const circles = useRef([]);
		const originalOpacities = useRef([]);

		useEffect(() => {
			const animateOpacity = (timestamp) => {
				const frequency = 0.0005;
				const amplitude = 0.33;

				circles.current.forEach((circle, index) => {
					const originalOpacity = originalOpacities.current[index];
					// originalOpacity +

					let x = Math.ceil(index / 5);
					let y = index - x * 5;
					const opacity =
						amplitude * Math.sin(timestamp * frequency + x) +
						0.25 +
						(amplitude * Math.sin(timestamp * frequency + y) + 0.25);
					circle.setAttribute('opacity', opacity);
				});

				requestAnimationFrame(animateOpacity);
			};

			const svgElement = rosterSvg.current;
			circles.current = Array.from(svgElement.querySelectorAll('circle'));
			originalOpacities.current = circles.current.map((circle) =>
				parseFloat(circle.getAttribute('opacity'))
			);

			requestAnimationFrame(animateOpacity);

			return () => {
				circles.current = [];
				originalOpacities.current = [];
			};
		}, []);
  return (
		<svg
			ref={rosterSvg}
			xmlns='http://www.w3.org/2000/svg'
			width='198'
			height='198'
			viewBox='0 0 198 198'
			preserveAspectRatio='xMidYMid slice'
			fill='none'
			className=' pulsing-circles'
		>
			<circle opacity='0.9' cx='19.8' cy='19.8' r='19.8' fill='white' />
			<circle opacity='0.8' cx='19.8' cy='59.4001' r='19.8' fill='white' />
			<circle opacity='0.6' cx='19.8' cy='99' r='19.8' fill='white' />
			<circle opacity='0.4' cx='19.8' cy='138.6' r='19.8' fill='white' />
			<circle opacity='0.2' cx='19.8' cy='178.2' r='19.8' fill='white' />
			<circle opacity='0.8' cx='59.4001' cy='19.8' r='19.8' fill='white' />
			<circle opacity='0.6' cx='59.4001' cy='59.4001' r='19.8' fill='white' />
			<circle opacity='0.4' cx='59.4001' cy='99' r='19.8' fill='white' />
			<circle opacity='0.2' cx='59.4001' cy='138.6' r='19.8' fill='white' />
			<circle opacity='0.4' cx='59.4001' cy='178.2' r='19.8' fill='white' />
			<circle opacity='0.6' cx='99.0002' cy='19.8' r='19.8' fill='white' />
			<circle opacity='0.4' cx='99.0002' cy='59.4001' r='19.8' fill='white' />
			<circle opacity='0.2' cx='99.0002' cy='99' r='19.8' fill='white' />
			<circle opacity='0.4' cx='99.0002' cy='138.6' r='19.8' fill='white' />
			<circle opacity='0.6' cx='99.0002' cy='178.2' r='19.8' fill='white' />
			<circle opacity='0.4' cx='138.6' cy='19.8' r='19.8' fill='white' />
			<circle opacity='0.2' cx='138.6' cy='59.4001' r='19.8' fill='white' />
			<circle opacity='0.4' cx='138.6' cy='99' r='19.8' fill='white' />
			<circle opacity='0.6' cx='138.6' cy='138.6' r='19.8' fill='white' />
			<circle opacity='0.8' cx='138.6' cy='178.2' r='19.8' fill='white' />
			<circle opacity='0.2' cx='178.2' cy='19.8' r='19.8' fill='white' />
			<circle opacity='0.4' cx='178.2' cy='59.4001' r='19.8' fill='white' />
			<circle opacity='0.6' cx='178.2' cy='99' r='19.8' fill='white' />
			<circle opacity='0.8' cx='178.2' cy='138.6' r='19.8' fill='white' />
			<circle opacity='0.9' cx='178.2' cy='178.2' r='19.8' fill='white' />
		</svg>
	);
}; */

export default OnBoarding;