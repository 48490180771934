import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';

import { useHistory } from 'react-router-dom';
import { apiClient } from '../../Helpers/apiCall';
import Loader from '../common/Loader';

import Timestamp from '../common/Timestamp';
import CustomTable from '../common/CustomTable';
import { HeaderAmount } from './HeaderAmount';

import UserName from '../common/UserName';

export default function FeedbackList({
	dashboardView,
	tableConfig,
	preFilter,
}) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [filter, setFilter] = useState();
	var history = useHistory();

	const onRowClick = (row) => {
		history.push('/admin/feedback/' + row.original.id);
	};

	const { feedback: dataSource } = state;
	const { items } = dataSource;

	const preCustomFilter = preFilter
		? preFilter
		: () => {
				return true;
		  };

	const processedRows = items
		.map((item) => {
			const newItem = { ...item };
			return newItem;
		})
		.filter(preCustomFilter)
		.reverse();

	const data = React.useMemo(() => {
		if (processedRows) {
			if (dashboardView) {
				return processedRows.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			} else {
				return processedRows;
			}
		} else {
			return [];
		}
	}, [dashboardView, processedRows]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			{
				Header: 'Aika',
				accessor: 'created_at',
				Cell: ({ value }) => {
					return <Timestamp timestamp={value} pure={true} />;
				},
			},
			{
				Header: 'Palaute',
				accessor: 'message',
			},
			{
				Header: 'Lähettäjä',
				accessor: 'author_user_id',
				Cell: ({ value }) => {
					return <UserName user_id={value} />;
				},
			},
		],
		[]
	);

	const addFeedback = () => {
		apiCall.ADD('feedback', {}).then((response) => {
			apiCall.GET('feedback');
			if (response?.status === 200) {
				history.push('/admin/feedback/' + response.data.data.id);
			}
		});
	};

	if (dataSource.loading === true || !dataSource.items) {
		return <Loader />;
	}

	return (
		<section className="common-list">
			<div className="common-list-header">
				<h1>Palautteet</h1>
				<FeedbackHeader data={processedRows} add={addFeedback} />
			</div>
			{!dashboardView && (
				<>
					<input
						placeholder="Etsi"
						name="etsi"
						type="text"
						value={filter || ''}
						onChange={(e) => setFilter(e.target.value)}
					/>
				</>
			)}
			{filter && filter !== '' && <h3>{`Tuloksia hakusanalla '${filter}'`}</h3>}
			<CustomTable
				data={data}
				columns={columns}
				onRowClick={onRowClick}
				filter={filter}
				hidePagination={dashboardView}
				config={tableConfig}
			/>
		</section>
	);
}

export const FeedbackHeader = (props) => {
	const data = props.data ? props.data : [];
	// const isPublished = data.filter((i) => i.is_published === 1);
	// const isSent = data.filter((i) => i.is_sent === 1);

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={data.length} />
				{/* <HeaderAmount label="Julkaistu" value={isPublished.length} /> */}
			</div>
			{/* <HeaderAdd add={props.add} /> */}
		</>
	);
};
