/* eslint-disable eqeqeq */
import React from 'react';
import { config } from '../../config';
import { isRequiredKey } from '../../Helpers/PlaceHelpers';

import {
	ICON_CAMERA,
	ICON_SWITCH_OFF,
	ICON_SWITCH_ON,
} from '../../Helpers/icons';
import Loader from './Loader';
import { format } from 'date-fns';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CustomInput = (props) => {
	const {
		field: key,
		label,
		data,
		handleOnChange,
		useTextarea,
		placeholder,
		autoComplete,
		type,
		disabled,
		min,
		max,
		isRequired: isRequiredProp,
	} = props;
	const isRequired = isRequiredProp ? isRequiredProp : isRequiredKey(key, data);

	return (
		<label className={`${isRequired ? 'required' : ''}`}>
			<span className="label">{label}</span>

			{useTextarea && (
				<textarea
					autoComplete={autoComplete ? autoComplete : 'off'}
					name={key}
					onChange={handleOnChange}
					value={data && data[key] ? data[key] : ''}
					required={isRequired}
					disabled={disabled}
				></textarea>
			)}
			{!useTextarea && (
				<input
					type={type ? type : 'text'}
					{...{ min, max }}
					autoComplete={autoComplete ? autoComplete : 'off'}
					name={key}
					placeholder={placeholder ? placeholder : label}
					value={data && data[key] ? data[key] : ''}
					onChange={handleOnChange}
					required={isRequired}
					disabled={disabled}
				/>
			)}
		</label>
	);
};

export const CustomRichTextArea = (props) => {
	const { field: key, label, data, handleOnChange } = props;
	const isRequired = isRequiredKey(key, data);

	return (
		<label className={`${isRequired ? 'required' : ''}`} htmlFor="">
			<span className="label">{label}</span>

			<CKEditor
				editor={ClassicEditor}
				data={data && data[key] ? data[key] : ''}
				onInit={(editor) => {
					// You can store the "editor" and use when it is needed.
					// console.log('Editor is ready to use!', editor);
				}}
				onChange={(event, editor) => {
					return handleOnChange(key, event, editor);
				}}
			/>
		</label>
	);
};

export const CustomCheckbox = (props) => {
	const {
		field: key,
		label,
		data,
		handleOnChange,
		subLabel,
		isRequired: isRequiredProp,
	} = props;
	const isRequired = isRequiredProp ? isRequiredProp : isRequiredKey(key, data);

	const isChecked = data && data[key] ? !!data[key] : false;

	return (
		<label className={`checkbox ${isRequired ? 'required' : ''}`}>
			{label && <span className="label">{label}</span>}
			<div>
				<input
					type="checkbox"
					autoComplete="off"
					name={key}
					checked={isChecked}
					value={isChecked ? 0 : 1}
					onChange={handleOnChange}
					required={isRequired}
				/>
				<span className="option">{subLabel}</span>
			</div>
		</label>
	);
};

export const CustomSwitch = (props) => {
	const { field: key, label, data, handleOnChange, disabled } = props;
	// const isRequired = isRequiredKey(key, data);
	// const is_checked = data[key];
	const isChecked = data && data[key] ? !!data[key] : false;

	return (
		<div
			onClick={() => handleOnChange({ target: { name: key } })}
			className={`${isChecked ? 'switcher checked' : 'switcher'} ${
				disabled ? 'disabled' : ''
			}`}
		>
			<span className="switch">
				{' '}
				<img src={isChecked ? ICON_SWITCH_ON : ICON_SWITCH_OFF} alt="" />
			</span>{' '}
			{label}
		</div>
	);
};

export const CustomPictureSelector = (props) => {
	const {
		field: key,
		fileSelectorRef,
		data,
		handleOnChange,
		isUploading,
		className,
	} = props;
	const imgstyle = {};

	if (data[key]) {
		imgstyle.backgroundImage = `url("${config.apiUrl}/file/${data[key]}")`;
	}

	// const isRequired = isRequiredKey(key, data);

	return (
		<div
			className={`${imgstyle.backgroundImage ? 'picture-selector picture-selected' : 'picture-selector'} ${isUploading ? 'isUploading' : ''} ${className ? className : ''}`}
			onClick={(e) => {
				fileSelectorRef.current.click();
				// e.preventDefault();
				e.stopPropagation();
			}}
		>
			<div className="picture-inner">
				<div className="picture" style={imgstyle}>
					<img src={ICON_CAMERA} aria-hidden alt="Upload Image icon" />
				</div>
			</div>
			<input
				accept="image/*"
				name="file"
				ref={fileSelectorRef}
				type="file"
				onChange={(e) => {
					handleOnChange(e.target.files[0], key);
				}}
			/>
			<div className="actions">
				{isUploading && <Loader className="dark" />}
				{!isUploading && (
					<button type="button" className="c-button button">
						Upload image
					</button>
				)}

				{!isUploading && imgstyle.backgroundImage && (
					<button
						type="button"
						className="c-button button secondary"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (
								window.confirm(
									'Are you sure you want to remove this file?'
								)
							) {
								handleOnChange(null, key);
							}
						}}
					>
						Remove image
					</button>
				)}
			</div>
		</div>
	);
};

export const CustomNestedInput = (props) => {
	const { field: key, parentKey, label, data, handleOnChange } = props;
	const index = props.index ? props.index : 0;

	const isRequired = isRequiredKey(parentKey, data);

	const value =
		data &&
		data[parentKey] &&
		data[parentKey][index] &&
		data[parentKey][index][key]
			? data[parentKey][index][key]
			: '';

	return (
		<label className={`${isRequired ? 'required' : ''}`}>
			<span className="label">{label}</span>
			<input
				type="text"
				autoComplete="off"
				name={key}
				data-parentkey={parentKey}
				data-index={index}
				value={value}
				onChange={handleOnChange}
				required={isRequired}
			/>
		</label>
	);
};

export const CustomSelect = (props) => {
	const { field: key, options, label, data, handleOnChange, disabled } = props;
	const isRequired = isRequiredKey(key, data);

	return (
		<div className={`${isRequired ? 'required' : ''}`}>
			<strong className='label'>{label}</strong>
			<div>
				<select
					name={key}
					value={data[key] != null ? data[key] : '0'}
					onChange={handleOnChange}					
					required={isRequired}
					disabled={disabled}
				>
					<option key="0" value="" disabled>
						Valitse
					</option>
					{options &&
						options.map(function (option, index) {
							const name =
								'description' in option
									? option.name + ' - ' + option.description
									: option.name;

							return (
								<option key={index} value={option.id}>
									{name}
								</option>
							);
						})}
				</select>
			</div>
		</div>
	);
};

export const CustomCheckboxes = (props) => {
	const { field: key, options, label, data, handleOnChange } = props;
	const isRequired = isRequiredKey(key, data);

	return (
		<>
			{label && <span className="label">{label}</span>}
			<div className={`checkboxesGroup ${isRequired ? 'required' : ''}`}>
				{options &&
					options.map(function (option, index) {
						const is_selected = data[key].find((element) => {
							return parseInt(element.id) === parseInt(option.id);
						})
							? true
							: false;

						return (
							<label
								key={option.id}
								className={`checkbox checkbox-label ${
									isRequired ? 'required' : ''
								}`}
							>
								<input
									checked={is_selected}
									onChange={(e) => {
										handleOnChange(e, options);
									}}
									type="checkbox"
									name={key}
									value={option.id}
									required={isRequired}
								/>
								<div>
									<span className="option">{option.name}</span>
									<span className="option-sublabel">{option.sublabel}</span>
								</div>
							</label>
						);
					})}
			</div>
		</>
	);
};

export const CustomRadio = (props) => {
	const {
		field: key,
		options,
		label,
		data,
		handleOnChange,
		className,
		cast,
		// defaultValue,
	} = props;
	const isRequired = isRequiredKey(key, data);

	return (
		<>
			{label && (
				<span className={`label ${isRequired ? 'required' : ''}`}>{label}</span>
			)}
			<div
				className={`radioGroup ${isRequired ? 'required' : ''} ${className}`}
			>
				{options &&
					options.map(function (option, index) {
						let isChecked = !cast
							? parseInt(data[key]) === parseInt(option.id)
							: data[key] == option.id;

						return (
							<label
								key={option.id}
								className={`radio radio-label ${isRequired ? 'required' : ''}`}
							>
								<input
									type="radio"
									name={key}
									checked={isChecked}
									value={option.id}
									onChange={(e) => {
										handleOnChange(e);
									}}
									disabled={option?.disabled ? option.disabled : false}
									required={isRequired}
								/>
								<div>
									<span className="option">{option.name}</span>
									<span className="option-sublabel">{option.sublabel}</span>
								</div>
							</label>
						);
					})}
			</div>
		</>
	);
};

/* ---------------------------------------------------------- */

export const createHandlers = (data, setdata, apiCall, state) => {
	const handleOnChangeForCheckBoxes = (event, allOptions) => {
		const { name, checked, value } = event.target;

		// console.log(allOptions);

		const categories =
			checked === true
				? [
						...data[name],
						allOptions.find((item) => parseInt(item.id) === parseInt(value)),
				  ]
				: [
						...data[name].filter((i) => {
							return parseInt(i.id) !== parseInt(value);
						}),
				  ];

		setdata({ ...data, [name]: categories });
	};

	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setdata({ ...data, [name]: value });
	};

	const handleOnMultipleChange = (newData) => {
		setdata({ ...data, ...newData });
	};

	const handleOnCustomChange = (manual) => {
		const { name, value } = manual;
		setdata({ ...data, [name]: value });
	};

	const handleOnCKEditorChange = (name, event, editor) => {
		const value = editor.getData();
		setdata({ ...data, [name]: value });
	};

	const handleOnDateChange = (event) => {
		const { name, value } = event;
		const valueString = format(value, 'yyyy-MM-dd HH:mm.ss');
		setdata({ ...data, [name]: valueString });
	};

	const handleOnChangeArray = (event) => {
		const { name, value } = event.target;
		var dkey = event.target.getAttribute('data-parentkey');
		var dindex = event.target.getAttribute('data-index');

		if (!data || !data[dkey] || !data[dkey][dindex]) {
			data[dkey] = [{}];
		}

		let newState = {
			...data,
			[dkey]: {
				...data[dkey],
				[dindex]: {
					...data[dkey][dindex],
					[name]: value,
				},
			},
		};

		setdata(newState);
	};

	const handleSwitch = (event) => {
		const { name } = event.target;
		const bool = !data[name] === true ? 1 : 0;
		setdata({ ...data, [name]: bool });
	};

	const sendFile = (file, ikey) => {
		const key = ikey ? ikey : 'thumb_id';

		if (file == null) {
			setdata({ ...data, [key]: null });
			
			return { [key]: null };
		} else {
			if (file.size > config.maxFileSize) {
				alert('Tiedoston maksimikoko on 10MB');
				return false;
			}
			return apiCall.UPLOAD('files', file).then((response) => {
				if (response.status === 200 && response.data && response.data.data) {
					setdata({ ...data, [key]: response.data.data.id });
				}
				return { [key]: response.data.data.id };
			});
		}
	};

	const sendMedia = (file) => {
		if (file) {

			if (file.size > config.maxFileSize) {
				alert('Tiedoston maksimikoko on 10MB');
				return false;
			}
			apiCall.UPLOAD('files', file).then((response) => {
				if (response.status === 200 && response.data && response.data.data) {
					setdata({ ...data, attachment: [...data.attachment, response.data.data] });
				}
			});
		}
	};

	return {
		handleOnChangeForCheckBoxes,
		handleOnChange,
		handleOnChangeArray,
		handleOnMultipleChange,
		handleSwitch,
		sendFile,
		sendMedia,
		handleOnDateChange,
		handleOnCustomChange,
		handleOnCKEditorChange,
		// setPrimaryPivot,
	};
};
