import React from 'react';
import addDark from './add-dark.svg';
import addPink from './add-pink.svg';
import addRosa from './add-rosa.svg';
import close from './close.svg';
import logoBig from './logo-big.svg';
import logoSmall from './logo-small.svg';
import showMore from './show-more.svg';
// import speechbubbleLila from './speechbubble_lila.svg';
import speechbubbleRosa from './speechbubble_rosa.svg';
import speechbubbleWhite from './speechbubble_white.svg';
import speechbubbleStep1 from './speechbubble-step-1.svg';
import speechbubbleStep2 from './speechbubble-step-2.svg';
import speechbubbleStep3 from './speechbubble-step-3.svg';
import heartEmpty from './heart_empty.svg';
import heartFull from './heart_full.svg';

export const AddDarkImg = () => <img src={addRosa} alt='icon' className='icon' />;
export const AddPinkImg = () => <img src={addRosa} alt='icon' className='icon' />;
export const CloseImg = () => <img src={close} alt="icon" className="icon" />;
export const LogoBigImg = () => (
	<img src={logoBig} alt="icon" className="icon" />
);
export const LogoSmallImg = () => (
	<img src={logoSmall} alt="icon" className="icon" />
);
export const ShowMoreImg = () => (
	<img src={showMore} alt="icon" className="icon showmore" />
);
// export const SpeechbubbleLilaImg = () => (
// 	<img src={speechbubbleLila} alt="icon" className="icon bubble" />
// );
export const SpeechbubbleRosaImg = () => (
	<img src={speechbubbleRosa} alt="icon" className="icon bubble" />
);
export const SpeechbubbleWhiteImg = () => (
	<img src={speechbubbleWhite} alt="icon" className="icon bubble" />
);
export const SpeechbubbleStep1Img = () => (
	<img src={speechbubbleStep1} alt="icon" className="icon bubble" />
);
export const SpeechbubbleStep2Img = () => (
	<img src={speechbubbleStep2} alt="icon" className="icon bubble" />
);
export const SpeechbubbleStep3Img = () => (
	<img src={speechbubbleStep3} alt="icon" className="icon bubble" />
);
export const HeartEmptyImg = () => (
	<img src={heartEmpty} alt="icon" className="icon bubble heart" />
);
export const HeartFullImg = () => (
	<img src={heartFull} alt="icon" className="icon bubble heart" />
);


export const CloseImg2 = () => {
	
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='32'
				height='32'
				fill='none'
				viewBox='0 0 32 32'
			>
				<path
					fill='#F5BBBF'
					fillRule='evenodd'
					d='M20.444 21.248a1.72 1.72 0 01-2.425 0L16 19.232l-2.024 2.027a1.72 1.72 0 01-2.426 0l-.804-.807a1.72 1.72 0 010-2.425l2.024-2.025-2.026-2.024a1.72 1.72 0 010-2.426l.806-.804a1.72 1.72 0 012.426 0L16 12.772l2.018-2.02a1.72 1.72 0 012.426 0l.806.806a1.72 1.72 0 010 2.426l-2.02 2.018 2.02 2.018a1.72 1.72 0 010 2.426l-.806.806v-.004zm6.867-16.565a15.998 15.998 0 00-22.628.006 15.99 15.99 0 000 22.622A16 16 0 0027.311 4.683z'
					clipRule='evenodd'
				></path>
			</svg>
		);	
}


export {
	addDark,
	addPink,
	addRosa,
	close,
	logoBig,
	logoSmall,
	showMore,
	// speechbubbleLila,
	speechbubbleRosa,
	speechbubbleWhite,
	speechbubbleStep1,
	speechbubbleStep2,
	speechbubbleStep3,
};
