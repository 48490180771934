/* eslint-disable eqeqeq */
import React from 'react';
import { Link } from 'react-router-dom';
// import { apiClient } from '../Helpers/apiCall';
import { useStore } from '../../store/useStore';

// import { Message } from './Feed';
import { ContactLink } from './Contacts';
import MainTitle from '../Components/common/MainTitle';
import { PodLink } from './Pods';
import { convertToPlainText, shortenText } from '../Helpers/common';


export default function Search({ isOpen, searchFilter, setSearchFilter }) {
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);
	// const { users, feed } = state;

	searchFilter = searchFilter.toLowerCase();

	const resultsUsers = (state?.users?.items ?? []).filter(
		(u) =>
			searchFilter != '' &&
			(u?.first_name?.toLowerCase().includes(searchFilter) ||
				u?.last_name?.toLowerCase().includes(searchFilter) ||
				u?.title?.toLowerCase().includes(searchFilter) ||
				u?.phone?.toLowerCase().includes(searchFilter) ||
				u?.email?.toLowerCase().includes(searchFilter))
	);

	const resultsPodlings = (state?.pods?.items ?? []).filter(
		(u) =>
			searchFilter != '' &&
			(u?.name?.toLowerCase().includes(searchFilter) ||
				u?.description?.toLowerCase().includes(searchFilter))
	);

	// const resultsFeed = (state?.feed?.items ?? []).filter((i) => {
	// 	return (
	// 		searchFilter != '' &&
	// 		(i.title.includes(searchFilter) || i.message.includes(searchFilter))
	// 	);
	// });
	// console.log();
	const resultsManual = (state?.pages?.items ?? []).filter((i) => {
		return (
			searchFilter != '' &&
			(i?.title?.rendered.toLowerCase().includes(searchFilter) ||
				i?.content?.rendered.toLowerCase().includes(searchFilter))
		);
	});

	return (
		<div className={`c-search ${isOpen ? 'm-open' : 'm-closed'}`}>
			<MainTitle>
				Search results
				<p>
					{resultsManual.length} pages, {' '}
					{resultsUsers.length} contacts
				</p>
			</MainTitle>
			{/* <input
				type="text"
				onChange={(e) => setSearchFilter(e.target.value)}
				value={searchFilter}
				placeholder="Etsi"
			/> */}

			<div className='search-results'>
				{resultsManual.length > 0 && (
					<div className='c-manual'>
						<div className='wp-nav'>
							<ul>
								{resultsManual.map((page) => {
									const plainText = convertToPlainText(
										page?.content?.rendered ?? ""
									);
									const description = shortenText(plainText, 255);
									
									return (
										<li key={page.id}>
											<Link
												to={`/manual/${page.slug}`}
												dangerouslySetInnerHTML={{
													__html: page?.title?.rendered ?? '',
												}}
											/>
											<p dangerouslySetInnerHTML={{
													__html: description
												}}
											/>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
				{/* {resultsFeed.length > 0 && (
				<div className="c-messages">
					<h3>Viestit</h3>
					{resultsFeed.map((item) => {
						return <Message feedItem={item} listMode={true} key={item.id} />;
					})}
				</div>
			)} */}
				{resultsUsers.length > 0 && (
					<ul className='c-contacts'>
						{resultsUsers.map((item) => {
							return (
								<li key={item.id}>
									<ContactLink user={item} key={item.id} />
								</li>
							);
						})}
					</ul>
				)}
				{resultsPodlings.length > 0 && (
					<ul className='c-podlings'>
						{resultsPodlings.map((item) => {
							return (
								<li key={item.id}>
									<PodLink pod={item} />
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
}
