import { replaceOrAddById } from '../App/Helpers/store';

export const weeklyInitialState = {
	initialized: false,
	loading: false,
	items: [],
	loadingItems: [],
};

export const weeklyReducer = (state = weeklyInitialState, action) => {
	switch (action.type) {
		case 'WEEKLY_ADD':
		case 'WEEKLY_DELETE':
			return state;

		case 'WEEKLY_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'WEEKLY_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case 'WEEKLY_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'WEEKLY_EDITING':
			return {
				...state,
				loading: false,
			};
		case 'WEEKLY_EDITED':
			return {
				...state,
				loading: false,
			};

		case 'PAGE_SET_LOADING':
			let nitems = [...state.items];
			let nindex = nitems.findIndex((val) => {
				// eslint-disable-next-line eqeqeq
				return val.id == action.id;
			});
			if (nindex > -1) {
				nitems[nindex].loading = true;
			}

			return {
				...state,
				items: nitems,
				loading: false,
			};

		case 'PAGE_GET':
			const items = replaceOrAddById(
				[...state.items],
				action.payload.data.data
			);

			return {
				...state,
				items,
				loading: false,
			};

		default:
			return state;
	}
};
