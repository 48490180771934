import React from 'react';
import ICON_ADD from '../../images/admin/add.svg';
import ICON_PEN from '../../images/admin/pen.svg';
import ICON_CHECK from '../../images/admin/checkmark.svg';
import ICON_CHECK_WHITE from '../../images/admin/checkmark_white.svg';
import ICON_STATUS_ARCHIVED from '../../images/admin/status_archived.svg';
import ICON_STATUS_HIDDEN from '../../images/admin/status_hidden.svg';
import ICON_STATUS_LIVE from '../../images/admin/status_live.svg';
import ICON_STATUS_OLD from '../../images/admin/status_old.svg';
import ICON_VISIBILITY_OK from '../../images/admin/visibility_ok.svg';
import ICON_VISIBILITY_OVER from '../../images/admin/visibility_over.svg';
import ICON_VISIBILITY_UNDER from '../../images/admin/visibility_under.svg';
import ICON_FILTER_ARROW from '../../images/admin/filter_arrow.svg';
import ICON_DROPDOWN_ARROW from '../../images/admin/dropdown_arrow.svg';
import ICON_CLOSE from '../../images/admin/close2.svg';
import ICON_TRASH from '../../images/admin/trash.svg';
import ICON_SWITCH_OFF from '../../images/admin/toggle_off.svg';
import ICON_SWITCH_ON from '../../images/admin/toggle_on.svg';
import ICON_CAMERA from '../../images/admin/camera.svg';
import ICON_DUPLICATE from '../../images/admin/duplicate.svg';

/* ... */
import ICON_UNREAD from '../../images/unread-1.svg';
import ICON_READ from '../../images/read-1.svg';
import ICON_PRIO_ALERT from '../../images/prio_3.svg';
import ICON_PRIO_MESSAGE from '../../images/prio_2.svg';
import ICON_PRIO_LOW from '../../images/prio_2.svg';

export {
	ICON_ADD,
	ICON_PEN,
	ICON_CHECK,
	ICON_CHECK_WHITE,
	ICON_STATUS_ARCHIVED,
	ICON_STATUS_HIDDEN,
	ICON_STATUS_LIVE,
	ICON_STATUS_OLD,
	ICON_VISIBILITY_OK,
	ICON_VISIBILITY_OVER,
	ICON_VISIBILITY_UNDER,
	ICON_FILTER_ARROW,
	ICON_DROPDOWN_ARROW,
	ICON_CLOSE,
	ICON_TRASH,
	ICON_SWITCH_OFF,
	ICON_SWITCH_ON,
	ICON_CAMERA,
	ICON_DUPLICATE,
};

export const ICON_ADD_IMG = (props) => {
	return <img {...props} src={ICON_ADD} alt="Lisää uusi" className="icon" />;
};

export const ICON_PEN_IMG = (props) => {
	return <img {...props} src={ICON_PEN} alt="Muokkaa" className="icon" />;
};

export const ICON_CHECK_IMG = (props) => {
	return <img {...props} src={ICON_CHECK} alt="ok" className="icon" />;
};

export const ICON_CHECK_WHITE_IMG = (props) => {
	return <img {...props} src={ICON_CHECK_WHITE} alt="ok" className="icon" />;
};

export const ICON_VISIBILITY_OK_IMG = (props) => {
	return <img {...props} src={ICON_VISIBILITY_OK} alt="OK" className="icon" />;
};
export const ICON_VISIBILITY_OVER_IMG = (props) => {
	return <img {...props} src={ICON_VISIBILITY_OVER} alt="+" className="icon" />;
};
export const ICON_VISIBILITY_UNDER_IMG = (props) => {
	return (
		<img {...props} src={ICON_VISIBILITY_UNDER} alt="-" className="icon" />
	);
};

export const ICON_STATUS_ARCHIVED_IMG = (props) => {
	return (
		<img
			{...props}
			src={ICON_STATUS_ARCHIVED}
			alt="Arkistoitu"
			className="icon"
		/>
	);
};

export const ICON_STATUS_HIDDEN_IMG = (props) => {
	return (
		<img
			{...props}
			src={ICON_STATUS_HIDDEN}
			alt="Piilotettu"
			className="icon"
		/>
	);
};

export const ICON_STATUS_LIVE_IMG = (props) => {
	return (
		<img {...props} src={ICON_STATUS_LIVE} alt="Julkaistu" className="icon" />
	);
};

export const ICON_STATUS_OLD_IMG = (props) => {
	return <img {...props} src={ICON_STATUS_OLD} alt="Vanha" className="icon" />;
};

export const ICON_FILTER_ARROW_IMG = (props) => {
	return (
		<img {...props} src={ICON_FILTER_ARROW} alt="Järjestä" className="icon" />
	);
};

export const ICON_DROPDOWN_ARROW_IMG = (props) => {
	return (
		<img {...props} src={ICON_DROPDOWN_ARROW} alt="Avaa" className="icon" />
	);
};

export const ICON_CLOSE_IMG = (props) => {
	return <img {...props} src={ICON_CLOSE} alt="Sulje" className="icon" />;
};
export const ICON_SWITCH_OFF_IMG = (props) => {
	return <img {...props} src={ICON_SWITCH_OFF} alt="-" className="icon" />;
};
export const ICON_SWITCH_ON_IMG = (props) => {
	return <img {...props} src={ICON_SWITCH_ON} alt="Päällä" className="icon" />;
};

export const ICON_TRASH_IMG = (props) => {
	return <img {...props} src={ICON_TRASH} alt="Poista" className="icon" />;
};

export const ICON_CAMERA_IMG = (props) => {
	return <img {...props} src={ICON_CAMERA} alt="kuva" className="icon" />;
};

export const ICON_DUPLICATE_IMG = (props) => {
	return (
		<img {...props} src={ICON_DUPLICATE} alt="duplikoi" className="icon" />
	);
};

/* --- */

export const ICON_UNREAD_IMG = (props) => {
	const className = (props.className ? props.className : '') + ' icon';
	return (
		<img {...props} src={ICON_UNREAD} alt="Unread" className={className} />
	);
};

export const ICON_READ_IMG = (props) => {
	const className = (props.className ? props.className : '') + ' icon';
	return <img {...props} src={ICON_READ} alt="Read" className={className} />;
};

export const ICON_PRIO_ALERT_IMG = (props) => {
	return <img {...props} src={ICON_PRIO_ALERT} alt="Tärkeä" className="icon" />;
};

export const ICON_PRIO_MESSAGE_IMG = (props) => {
	return (
		<img {...props} src={ICON_PRIO_MESSAGE} alt="Normaali" className="icon" />
	);
};

export const ICON_PRIO_LOW_IMG = (props) => {
	return <img {...props} src={ICON_PRIO_LOW} alt="Alhainen" className="icon" />;
};

/* TRP */

export const SVG_PERSON = (props) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10 0a6.25 6.25 0 110 12.5A6.25 6.25 0 0110 0zm7.16 13.04l-2.786-.697c-2.927 2.106-6.474 1.636-8.748 0l-2.785.697A3.75 3.75 0 000 16.678v1.447C0 19.161.84 20 1.875 20h16.25C19.161 20 20 19.16 20 18.125v-1.447a3.75 3.75 0 00-2.84-3.638z"
				fill="#FFF"
				fillRule="nonzero"
			/>
		</svg>
	);
};

export const SVG_LOGO = (props) => {
 return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="64"
			height="64"
			fill="none"
			viewBox="0 0 64 64"
		>
			<g fill="#fff" clipPath="url(#clip0_490_9)">
				<path d="M32 0C14.355 0 0 14.355 0 32c0 17.644 14.355 31.999 32 31.999 17.644 0 31.999-14.355 31.999-32C63.999 14.356 49.645 0 31.999 0zm0 58.869c-14.545 0-26.378-12.053-26.378-26.87C5.622 17.184 17.455 5.132 32 5.132c14.544 0 26.377 12.053 26.377 26.87C58.377 46.817 46.544 58.87 32 58.87v-.001z"></path>
				<path d="M32.228 14.421H19.374v34.804h12.854c3.044 0 5.775-.581 8.096-1.705l5.06 4.128 3.316-4.032-4.113-3.273c2.735-3.021 4.183-7.34 4.183-12.52 0-10.733-6.339-17.402-16.542-17.402zm8.114 26.338l-4.991-4.036-3.368 4.143 3.728 2.817c-1.167.29-2.495.438-3.96.438h-6.7V19.523h6.7c4.015 0 7.035 1.166 8.508 3.287 1.629 2.327 2.263 4.853 2.263 9.012 0 4.159-.634 6.664-2.18 8.936v.001z"></path>
			</g>
			<defs>
				<clipPath id="clip0_490_9">
					<path fill="#fff" d="M0 0H64V64H0z"></path>
				</clipPath>
			</defs>
		</svg>
 );	
}

export const SVG_BACK = (props) => {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="64"
			height="64"
			fill="none"
			viewBox="0 0 64 64"
		>
			<g>
				<circle cx="32" cy="32" r="29" stroke="#fff" strokeWidth="6"></circle>
				<path
					fill="#fff"
					d="M34.477 22l-5.273 5.4c-.765.766-1.743 1.616-2.636 2.424 1.148-.042 2.764-.042 3.657-.042h16.754v5.528H30.225c-.936 0-2.466 0-3.657-.043 1.02.936 1.87 1.829 2.764 2.722l5.145 5.273h-7.526L17 32.63 26.95 22h7.527z"
				></path>
			</g>
		</svg>
	);
}

// export const SVG_LOGO = (props) => {
// 	return (
// 		<svg
// 			width="120"
// 			height="37"
// 			viewBox="0 0 120 37"
// 			xmlns="http://www.w3.org/2000/svg"
// 			xmlnsXlink="http://www.w3.org/1999/xlink"
// 		>
// 			<defs>
// 				<path d="M.073.232h22.109V30H.073z" />
// 				<path d="M.216.232h22.108V30H.216z" />
// 				<path d="M0 35.693h119.275V0H0z" />
// 			</defs>
// 			<g fill="none" fillRule="evenodd">
// 				<path
// 					d="M3.411 2.375h5.824v7.46h6.102v5.925H9.235v4.783c0 2.855 2.267 5.068 5.23 5.068h.872v5.925h-.871c-5.929 0-11.055-4.747-11.055-10.993V15.76H.517V9.835h2.894v-7.46zM30.09 14.867c-2.127 0-4.044 1.357-4.812 3.284h9.59c-.837-1.891-2.615-3.284-4.777-3.284m-.036 13.706h.872v5.925h-.872c-6.102 0-11.019-5.032-11.019-11.279v-2.998c0-6.245 4.917-11.278 11.02-11.278 6.102 0 11.054 5.033 11.054 11.278v3.891H24.93c.418 2.534 2.615 4.461 5.125 4.461M51.296 6.837v3.498c1.534-.892 3.278-1.392 5.23-1.392h.593v5.96h-.592c-2.93 0-5.23 2.356-5.23 5.318v10.423h-5.825V6.837h5.824z"
// 					fill="#FFF"
// 					fillRule="nonzero"
// 				/>
// 				<g transform="translate(60.431 6.605)">
// 					<path
// 						d="M5.897 13.652c0 2.963 2.336 5.319 5.23 5.319 2.895 0 5.231-2.356 5.231-5.319 0-2.962-2.336-5.354-5.23-5.354-2.895 0-5.231 2.392-5.231 5.354zm0-13.42V3.73c1.534-.892 3.277-1.392 5.23-1.392 6.103 0 11.055 5.033 11.055 11.279 0 6.245-4.952 11.314-11.054 11.314-1.954 0-3.697-.5-5.231-1.392V30H.073V.232h5.824z"
// 						fill="#FFF"
// 						fillRule="nonzero"
// 					/>
// 				</g>
// 				<g transform="translate(86.724 6.605)">
// 					<path
// 						d="M6.04 13.652c0 2.963 2.336 5.319 5.23 5.319 2.894 0 5.231-2.356 5.231-5.319 0-2.962-2.337-5.354-5.23-5.354-2.895 0-5.232 2.392-5.232 5.354zm0-13.42V3.73c1.533-.892 3.277-1.392 5.23-1.392 6.103 0 11.054 5.033 11.054 11.279 0 6.245-4.951 11.314-11.054 11.314-1.953 0-3.697-.5-5.23-1.392V30H.215V.232h5.823z"
// 						fill="#FFF"
// 						fillRule="nonzero"
// 					/>
// 				</g>
// 				<g transform="translate(.517 .912)">
// 					<path
// 						d="M112.859 29.732h5.823V8.923h-5.823v20.81zm2.93-22.593c-1.954 0-3.488-1.607-3.488-3.57 0-1.998 1.534-3.569 3.487-3.569 1.918 0 3.487 1.571 3.487 3.57 0 1.962-1.569 3.569-3.487 3.569z"
// 						fill="#FFF"
// 						fillRule="nonzero"
// 					/>
// 				</g>
// 			</g>
// 		</svg>
// 	);
// };
