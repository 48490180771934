import React from 'react';
// import { UserCard } from './UserCard';
// import Spinner from './Spinner';
import { useTrail, animated } from 'react-spring';
import { useStore } from '../../store/useStore';

// import DRAAMAPOLI_AD from '../Images/Draamapoli_logo_800x800.jpg';
// import YearReview from './YearReview';
import { ApplaudCard } from './ApplaudCard';
import { useLocation } from 'react-router-dom';
/* eslint-disable react/jsx-no-target-blank */

export default function ApplaudsList({ visibleUserCards, newComment}) {
	const { state } = useStore();
	const { applauds: commentsState } = state;
	const location = useLocation();

	const [springs, set] = useTrail(visibleUserCards.length, (index) => ({
		opacity: 0.1,
		config: {
			mass: 0.3,
			tension: 300,
		}
	}));

	set({ opacity: 1 });

	return (
		<div className={`cardlist ${commentsState.loading ? 'm-loading' : ''}`}>
			<ul>
				{visibleUserCards.map((card, index) => (
					<animated.li key={card.user.email} style={springs[index]}>
						{!card.isAd && <ApplaudCard card={card} newComment={newComment} isOpen={location.hash === `#${card.user.email}`} />}

					</animated.li>
				))}
			</ul>
		</div>
	);
}

/*


	//inject ads!
	// let injectpoint = Math.min(visibleUserCards.length / 2, 12);
	// visibleUserCards.splice(injectpoint, 0, {
	// 	user: { email: 'draamapoli_ad' },
	// 	isAd: true,
	// });

	// let injectpoint2 = Math.min(visibleUserCards.length / 3, 6);
	// visibleUserCards.splice(injectpoint2, 0, {
	// 	user: { email: 'year-review-2022' },
	// 	isAd: true,
	// });


	{card.isAd && card.user.email === 'draamapoli_ad' && (
		<a
			href='https://draamattu.dqcomms.com/anonyymi-kakkalaari'
			className='ad user-card'
			target='_blank'
			rel='noopener'
		>
			<img src={DRAAMAPOLI_AD} alt='Draamapoli' />
		</a>
	)}
	
	{card.isAd && card.user.email === 'year-review-2022' && (
		<YearReview year='2022' />
	)}


*/