/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useStore } from '../../store/useStore';
import { Link } from 'react-router-dom';
import { skiplogin } from '../Views/LoginPage';
import { config } from '../config';

import { name, version } from '../../../package.json';

export default function Footer() {
	const { state, dispatch } = useStore();

	const { user } = state;
	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	return (
		<footer>
			<div className='container'>
				{user.loggedIn && user.data.role_id === 2 && <DebugNav />}
				{!user.loggedIn &&
					process.env.REACT_APP_ENV !== 'production' && (
						<div>
							<button onClick={() => skiplogin(dispatch)}>skip login</button>
						</div>
					)}
				{user.loggedIn && <button onClick={logoutAction}>Log out</button>}
				{/* <p className='problems-signing-in'>
					<button
						className='m-notabtn'
						onClick={() => window.location.reload()}
					>
						Trouble logging in? Close all tabs, reload the page and try again.
					</button>
				</p> */}
				{/* <p className='version' onClick={() => console.log(process)}>
					VERSION: {process.env.VERCEL_GIT_COMMIT_SHA}
					<br />
					<i>{process.env.VERCEL_GIT_COMMIT_MESSAGE}</i>
				</p>				 */}
				<p>
					{name} - {version}
				</p>
				<p className='external-links'>
					<a target='_blank' href='https://dqcomms.com/' rel='noopener'>
						dqcomms.com
					</a>
					{' | '}
					<a target='_blank' href={config.slack.supportChannel} rel='noopener'>
						#backstage on Slack
					</a>
				</p>
			</div>
		</footer>
	);
}


const DebugNav = () => {
	const { state, dispatch } = useStore();
	// const apiCall = apiClient(state, dispatch);

	const loggedIn = state.user.loggedIn;

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	const links = !loggedIn
		? [{ name: 'login', href: '/' }]
		: [
				{ name: 'feed', href: '/' },
				{ name: 'manual', href: '/manual' },
				{ name: 'contacts', href: '/contacts' },
				{ name: 'feedback', href: '/feedback' },
				{ name: 'profile', href: '/profile' },
				{ name: 'admin', href: '/admin' },
		  ];

	return (
		<ul className="debug-links">
			{loggedIn && (
				<li>
					<button onClick={logoutAction}>[Log out]</button>
				</li>
			)}
			{links &&
				links.map((i) => (
					<li key={i.href}>
						<Link to={i.href}>{i.name}</Link>
					</li>
				))}
		</ul>
	);
};