export const config = {
	localStoragePrefix: 'draamattu-',
	isDev: process.env.REACT_APP_ENV !== 'production',
	devPassword: '',
	devEmail: '',
	devPhone: '+358407760362',
	apiUrl:
		process.env.REACT_APP_ENV === 'production'
			? 'https://draamattu-api.atk.dqcomms.com/api/v1'
			: process.env.REACT_APP_ENV === 'staging'
			? 'https://draamattu-api.atk.dqcomms.com/api/v1'
			: 'http://draamattu-api.atk7.dqcomms.com:81/api/v1',

	mapsApiKey: '',
	siteUrl:
		process.env.REACT_APP_ENV === 'production'
			? 'https://backstage.dqcomms.com'
			: process.env.REACT_APP_ENV === 'staging'
			? 'https://backstage.dqcomms.com'
			: 'localhost:3000',
	maxFileSize: 10 * 1024 * 1024,
	localeCompare: {
		locale: 'fi',
	},
	firebase: {
		apiKey: 'AIzaSyBKukgLKSvd08w8mWIOOiZl0HnIMUC7lCY',
		authDomain: 'draamattu-2b32a.firebaseapp.com',
		projectId: 'draamattu-2b32a',
		storageBucket: 'draamattu-2b32a.appspot.com',
		messagingSenderId: '717577632112',
		appId: '1:717577632112:web:eb87d1f93c0c7e1c45f7b5',
		measurementId: 'G-M39PQZ9B3Z',

		// apiKey: 'AIzaSyCEhIh_KmtGBYL6Z1YH89IawtUb1H1_UtU',
		// authDomain: 'trp-test-f3cbc.firebaseapp.com',
		// databaseURL: 'https://trp-test-f3cbc.firebaseio.com',
		// projectId: 'trp-test-f3cbc',
		// storageBucket: 'trp-test-f3cbc.appspot.com',
		// messagingSenderId: '556129729531',
		// appId: '1:556129729531:web:0ccd9af9dbdd59242c695e',
		// vapid:
		// 	'BGl7_gGAWObCSxz0XFu7d412tiwR9U29KofyEoKXiUnq8dWpYvrkMDi6JwEEbASPuTaRKGhW169PNaxWi0K_GVY',
	},
	slack: {
		baseUrl: 'https://dqc.slack.com',
		supportChannel: 'https://dqc.slack.com/archives/C05RDDXU0JY'
	},
	googleClient: {
		id: '177889218619-o3rf66bjdedejn73nbhla8tr7ff327kr.apps.googleusercontent.com',
		hd: 'dqcomms.com',
	},
	draamattupiiri: {
		minimumComments: 3,
	},
};