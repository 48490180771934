import React, { useState } from 'react';

import Loader from '../common/Loader';

import Timestamp from '../common/Timestamp';
import CustomTable from '../common/CustomTable';
import { HeaderAmount } from './HeaderAmount';
import UserName from '../common/UserName';

export default function AnswerList({
	items,
	dashboardView,
	tableConfig,
	preFilter,
}) {
	// const { state } = useStore();
	const [filter, setFilter] = useState();
	// const apiCall = apiClient(state, dispatch);
	// var history = useHistory();
	// const { messages: dataSource } = state;

	const preCustomFilter = preFilter
		? preFilter
		: () => {
				return true;
		  };

	const processedRows = items
		.map((item) => {
			const newItem = { ...item };
			return newItem;
		})
		.filter(preCustomFilter)
		.reverse();

	const data = React.useMemo(() => {
		if (processedRows) {
			if (dashboardView) {
				return processedRows.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			} else {
				return processedRows;
			}
		} else {
			return [];
		}
	}, [dashboardView, processedRows]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			{
				Header: 'Vastaus',
				accessor: 'ack_data',
				Cell: ({ value, row }) => {
					return <p className="message-value">{value}</p>;
				},
			},
			{
				Header: 'user_id',
				accessor: 'user_id',
				Cell: ({ value }) => {
					return <UserName user_id={value} useLink={true} />;
				},
			},
			{
				Header: 'Aika',
				accessor: 'created_at',
				Cell: ({ value }) => {
					return (
						<Timestamp
							timestamp={value}
							pure={true}
							format={'dd.MM.yyyy HH:mm:ss'}
						/>
					);
				},
			},
		],
		[]
	);

	if (!items) {
		return <Loader className="dark" />;
	}

	return (
		<section className="common-list messagelist">
			<div className="common-list-header">
				<h1>Vastauslogi</h1>
				<MessageHeader data={processedRows} />
			</div>
			{!dashboardView && (
				<>
					<input
						placeholder="Etsi"
						name="etsi"
						type="text"
						value={filter || ''}
						onChange={(e) => setFilter(e.target.value)}
					/>
				</>
			)}
			{filter && filter !== '' && <h3>{`Tuloksia hakusanalla '${filter}'`}</h3>}
			<CustomTable
				data={data}
				columns={columns}
				filter={filter}
				hidePagination={false}
				config={tableConfig}
			/>
		</section>
	);
}

export const MessageHeader = (props) => {
	const data = props.data ? props.data : [];
	// const isPublished = data.filter((i) => i.is_published === 1);
	// const isSent = data.filter((i) => i.is_sent === 1);

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={data.length} />
				{/* <HeaderAmount label="Julkaistu" value={isPublished.length} /> */}
			</div>
		</>
	);
};
