const jwtDecode = require('jwt-decode');

export default class JWT {
	/**
	 * Parses user from token
	 *
	 * @param token
	 * @return {User|undefined}
	 */
	static getUserFromToken(token) {
		if (token) {
			const {
				context: { user },
			} = JWT.decodeToken(token);
			return user;
		}
		return undefined;
	}

	/**
	 * Removes token from store
	 * used when logging out
	 */
	// static removeToken() {
	// 	storeJS.remove(JWT.TOKEN_NAME);
	// }

	/**
	 * getToken
	 *
	 * Returns token from store
	 *
	 * @return {string}
	 */
	// static getToken() {
	// 	return storeJS.get(JWT.TOKEN_NAME);
	// }

	/**
	 * setToken
	 *
	 * Saves token to store
	 *
	 * @param {string} token
	 */
	// static setToken(token) {
	// 	if (JWT.lockToken) return;

	// 	storeJS.set(JWT.TOKEN_NAME, token);
	// }

	/**
	 * decodeToken
	 *
	 * @param {string} token
	 * @return {object}
	 */
	static decodeToken(token) {
		return jwtDecode(token);
	}

	/**
	 * @param {string|Object}token
	 */
	static isValidToken(token) {
		if (typeof token === 'string') {
			token = JWT.decodeToken(token);
		}
		return token.exp > new Date().getTime() / 1000;
	}
}
JWT.TOKEN_NAME = 'BEARER_TOKEN';
