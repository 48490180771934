import React from "react";
export default function Loader(props) {
	return (
		<div className={`lds-ellipsis ${props.className}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}
