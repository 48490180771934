import { toast } from 'react-toastify';
import { replaceOrAddById } from '../App/Helpers/store';

export const pagesInitialState = {
	initialized: false,
	loading: false,
	items: [],
	loadingItems: [],
};

export const pagesReducer = (state = pagesInitialState, action) => {
	switch (action.type) {
		case 'PAGES_ADD':
		case 'PAGES_DELETE':
			return state;

		case 'PAGES_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'PAGES_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case 'PAGES_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'PAGES_EDITING':
			return {
				...state,
				loading: false,
			};
		case 'PAGES_EDITED':
			toast.success('Tiedot tallennettu');
			return {
				...state,
				loading: false,
			};

		case 'PAGE_SET_LOADING':
			let nitems = [...state.items];
			let nindex = nitems.findIndex((val) => {
				// eslint-disable-next-line eqeqeq
				return val.id == action.id;
			});
			if (nindex > -1) {
				nitems[nindex].loading = true;
			}

			return {
				...state,
				items: nitems,
				loading: false,
			};

		case 'PAGE_GET':
			const items = replaceOrAddById(
				[...state.items],
				action.payload.data.data
			);

			return {
				...state,
				items,
				loading: false,
			};

		default:
			return state;
	}
};
