import firebase from 'firebase/app';
import 'firebase/messaging';
import { config } from './App/config';

var messaging;
if (firebase.messaging.isSupported()) {

	
	// const initializedFirebaseApp = firebase.initializeApp(config.firebase);
	const initializedFirebaseApp = !firebase.apps.length
		? firebase.initializeApp(config.firebase)
		: firebase.app();
	messaging = initializedFirebaseApp.messaging();

} else {
	messaging = false;
}
export { messaging };
