export const replaceOrAddById = (items, replace) => {
	for (let i = 0; i < items.length; i++) {
		// eslint-disable-next-line eqeqeq
		if (items[i].id == replace.id) {
			items[i] = replace;
			return items;
		}
	}
	items.push(replace);
	return items;
};
