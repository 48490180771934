// import { replaceOrAddById } from '../App/Helpers/store';
// import { config } from '../App/config';

export const igfeedInitialState = {
	loading: false,
	items: false
};

export const igfeedReducer = (state = igfeedInitialState, action) => {
	switch (action.type) {
		case 'IGFEED_LOADING':
			return {
				...state,
				loading: true,
			};
			
		case 'IGFEED_GET':
			return {
				...state,
				loading: false,
				items: action?.payload?.data?.posts ?? [],
			};

		case 'IGFEED_ERROR':
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
};
