import React from "react";
import { Route, Redirect } from 'react-router-dom';
import AppHeader from '../Components/AppHeader';
// import LoginForm from '../Views/LoginForm';
import LoginPage from '../Views/LoginPage';
import MainTitle from '../Components/common/MainTitle';

const LoginRoute = () => {
	return (
		<>
			<Route path='/intro'>
				<div className='container login-container'>
					<AppHeader />
					<div className='page login-page'>
						<MainTitle className={'center'}>
							Welcome to <span>the Royal Backstage</span>
						</MainTitle>
						<div className='login-btn-wrapper'>
							<h4>
								<strong>Here's how to get started:</strong>
							</h4>
							<ol>
								<li>
									{/* <p>
								</p> */}
									Save this website to your home screen.
									{/* <p>
									Android: 
									Apple: https://support.apple.com/guide/shortcuts/add-a-shortcut-to-the-home-screen-apd735880972/ios
								</p> */}
								</li>
								<li>Open the saved app and login with your DQCOMMS account</li>
								<li>
									Enable notifications to keep up to date with the latest news
								</li>
								<li>That's it!</li>
							</ol>
						</div>
					</div>
				</div>
			</Route>
			<Route path='/' exact>
				<div className='container login-container'>
					<AppHeader />
					<LoginPage />
				</div>
			</Route>
			<Route path='*' render={() => <Redirect to='/' />} />
		</>
	);
};

export default LoginRoute;