import React from 'react';
// import ICON_TRASH from '../../../images/trash.svg';
// import ICON_CAMERA from '../../../images/camera.svg';
// import React, { useState, useEffect, useRef } from 'react';
import {
	ICON_TRASH,
	ICON_CAMERA,
	ICON_VISIBILITY_OK,
} from '../../Helpers/icons';


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loader from '../common/Loader';

const compareFiles = (file1, file2) => {
	return (file1?.pivot?.order ?? 99999) - (file2?.pivot?.order ?? 99999);
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer

	// padding: grid * 2,
	// margin: `0 ${grid}px 0 0`,

	// change background colour if dragging
	// background: isDragging ? 'lightgreen' : '',
	// opacity: isDragging ? '0.85' : '1',

	// styles we need to apply on draggables
	...draggableStyle,
});

const File = ({ item, index, onRemove }) => {
	return (
		<Draggable draggableId={`drag-media-${item.id}`} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="media-item"
					style={getItemStyle(
						snapshot.isDragging,
						provided.draggableProps.style
					)}
				>
					<strong>{item.name}</strong>
					<span>{item.type}</span>
					<a href={item.publicUrl} target="_blank" rel="noreferrer">
						<img src={ICON_VISIBILITY_OK} alt="" />
					</a>
					<img
						alt="Poista"
						src={ICON_TRASH}
						className={'delete-media'}
						onClick={() => {
							onRemove(index);
						}}
					/>
				</div>
			)}
		</Draggable>
	);
};

const FileList = React.memo(function FileList({ items, onRemove }) {
	return items.map((item, index) => (
		<File item={item} index={index} key={item.id} onRemove={onRemove} />
	));
});

/*
	1. Add media:
		a. upload file -> as response:
		b. add file to media array

	1. List media:
	2. Reorder array

		Saved only when hit save?

*/

const FileManager = (props) => {
	const {
		// field: key,
		fileSelectorRef,
		data,
		handleOnChange,
		upload,
		isUploading,
	} = props;

	const files = { items: [...(data?.attachment ?? [])] };

	const reorder = (list, startIndex, endIndex) => {
		let result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		result = result.map((item, index) => {
			if (item.pivot && item.pivot.order) {
				item.pivot.order = index;
			} else {
				item.pivot = { order: index };
			}
			return item;
		});

		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const items = reorder(
			files.items,
			result.source.index,
			result.destination.index
		);

		handleOnChange({ name: 'attachment', value: items });
	};

	const removeFile = (removeIndex) => {
		if (window.confirm('Haluatko varmasti poistaa tämän tiedoston?')) {
			const items = files.items.filter((item, index) => {
				return index !== removeIndex;
			});
			handleOnChange({ name: 'attachment', value: items });
		}
	};

	files.items = files?.items ? files.items.sort(compareFiles) : [];

	return (
		<div className="file-manager">
			<div
				className={'upload'}
				// onClick={(e) => {
				// 	console.log(fileSelectorRef.current, 'click!');
				// 	fileSelectorRef.current.click();
				// 	e.preventDefault();
				// 	e.stopPropagation();
				// }}
			>
				<img src={ICON_CAMERA} aria-hidden alt="Upload Image icon" />
				<input
					name="file"
					ref={fileSelectorRef}
					type="file"
					onChange={(e) => {
						upload(e.target.files[0]);
					}}
				/>
				{isUploading && <Loader className="dark" />}
				{/* {!isUploading && <button type="button">Valitse tiedosto </button>} */}
			</div>
			<div className="dragdrop-context">
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="list" direction="horizontal">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className="droppable-area"
							>
								<FileList items={files.items} onRemove={removeFile} />
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</div>
	);
};

export default FileManager;
