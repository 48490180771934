import React from 'react';
import ReactDOM from 'react-dom';

import { StoreProvider } from './store/useStore';
// import * as serviceWorker from './serviceWorker';
import AppContainer from './App/AppContainer';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<StoreProvider>
		<AppContainer />
	</StoreProvider>,
	document.getElementById('root')
);

// NEW SW:
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				if (event.target.state === 'activated') {

					console.log('Reloading service worker!');
					window.location.reload();
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
