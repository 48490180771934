import React from 'react';
import { useStore } from '../../store/useStore';
import { FeedTimestamp } from './common/Timestamp';
import { apiClient } from '../Helpers/apiCall';


export const LikeCount = ({ comment }) => {
	const { state, dispatch } = useStore();

	const hasLiked = state.applauds.likes.includes(comment.id);

	const onLike = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const apiCall = apiClient(state, dispatch);
		// eslint-disable-next-line no-unused-vars
		const res = apiCall
			.POSTRAW(`applauds/${comment.id}/like`, 'LIKE_APPLAUD', {
				like: hasLiked ? 0 : 1,
			})
			.then((response) => {
				if (response.status === 200) {
					//refetch my likes in the post
					//refetch card 
					apiCall.GET_APPLAUD(comment.id);
				}
			}).catch((Error) => {
				dispatch({ type: 'LIKE_APPLAUD_ERROR' });
			});
	};

	return (
		<div
			className={`heart ${hasLiked ? 'liked' : ''} hvr-bounce-in`}
			onClick={onLike}
		>
			{/* ♥ */}
			<span>{comment.like_count > 0 ? comment.like_count : ' '}</span>
		</div>
	);
};

export default function Applaud(props) {
	const { comment } = props;
	const showTime = (props && props.showTime) || false;
	return (
		<div className='comment'>
			<p className='message'>{comment.message}</p>
			{showTime && <LikeCount comment={comment} />}
			{showTime && (
				<span className='timeago'>
					{/* <TimeAgo date={comment.added} /> */}
					<FeedTimestamp timestamp={comment.created_at} />
				</span>
			)}
		</div>
	);
}
