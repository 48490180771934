import React from 'react';
import { Link } from 'react-router-dom';

import MainTitle from '../Components/common/MainTitle';
import IGFeed from '../Components/IGFeed';
import Ticker from '../Components/Ticker';
import Feed from './Feed';


import IconRoster from '../../images/icon_roster.svg';
import IconApplauds from '../../images/icon_applauds.svg';
import IconToolkit from '../../images/icon_toolkit.svg';
import IconWeekly from '../../images/icon_weekly.svg';
import IconFeedback from '../../images/icon_feedback.svg';
import IconPods from '../../images/icon_pods.svg';

/* 
const SnazzyMenu = () => {

	const links = [
		{
			img: Book,
			label: 'Toolkit',
			link: '/manual',
		},
		{
			img: Phone,
			label: 'Roster',
			link: '/contacts',
		},
		{
			img: Queen,
			label: 'Applauds',
			link: '/draamattupiiri',
		},
		{
			img: Feedback,
			label: 'Feedback',
			link: '/feedback',
		},
		{
			img: Book,
			label: 'Queendom’s weekly',
			link: '/weekly',
		},
	];

	return (
		<ul className='c-snazzymenu'>
			{links.map((i) => {
				return (
					<li key={i.link}>
						<Link
							to={{
								pathname: i.link,
							}}
						>
							{[...Array(4)].map((x, b) => (
								<img
									key={`img-${b}`}
									src={i.img}
									className={`level-${b}`}
									alt={i.label}
								/>
							))}
							<span>{i.label}</span>
						</Link>
					</li>
				);
			})}
		</ul>
	);
}
 */
const IconMenu = () => {

	const links = [
		{
			img: IconToolkit,
			label: 'Toolkit',
			link: '/manual',
		},
		{
			img: IconRoster,
			label: 'Roster',
			link: '/contacts',
		},
		{
			img: IconApplauds,
			label: 'Applauds',
			link: '/draamattupiiri',
		},
		{
			img: IconFeedback,
			label: 'Feedback',
			link: '/feedback',
		},
		{
			img: IconWeekly,
			label: 'Weekly',
			link: '/weekly',
		},
		{
			img: IconPods,
			label: 'Pods',
			link: '/podlings',
		},
	];

	return (
		<ul className='c-iconmenu'>
			{links.map((i) => {
				return (
					<li key={i.link}>
						<Link
							to={{
								pathname: i.link,
							}}
						>
							<img
								key={`img`}
								src={i.img}
								alt={i.label}
							/>
							<span>{i.label}</span>
						</Link>
					</li>
				);
			})}
		</ul>
	);
}

export const Frontpage = () => {



	return (
		<div className="c-frontpage">
			<MainTitle className={'center'}>Royal Backstage</MainTitle>
			<Ticker />

			<div className="widelayout">
				{/* <SnazzyMenu /> */}
				<IconMenu />

				<Feed />
				<IGFeed />				
			</div>
		</div>
	);
};
