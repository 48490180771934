import React, { useEffect, useRef, useState } from 'react';
// import { openCommentModal } from '../Reducers/AppReducer';
import { useStore } from '../../store/useStore';
// import { GlobalStore } from '../store';
import Applaud from './Applaud';
// import Spinner from './Spinner';
import {
	AddDarkImg, AddPinkImg, ShowMoreImg,
	SpeechbubbleWhiteImg,
	SpeechbubbleRosaImg,
	HeartFullImg,
} from '../../images/draamattupiiri_icons/icons';
// import ReactGA from 'react-ga';

export const ApplaudCard = (props) => {
	const { state } = useStore()
	const {
		user: { data: currentUser },
	} = state;
	const {
		newComment,
		card: { user, applauds },
	} = props;

	const userCardRef = useRef();
	
	const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
	const latestComment = [...applauds].shift();


	useEffect(() => {
		if (props.isOpen) {
			console.log('props.isOpen', isOpen);
			setIsOpen(true);
			var newTarget = userCardRef.current;

			setTimeout(() => {
				newTarget.scrollIntoView({ behavior: 'smooth' });
			}, 50);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.isOpen]);



	const toggle = (e) => {
		if (e && e.target) {

			console.log(userCardRef.current);
			// var newTarget = e.target.closest('.user-card');
			var newTarget = userCardRef.current;

			setTimeout(() => {
				newTarget.scrollIntoView({ behavior: 'smooth' });
			}, 50);

		}

		setIsOpen(!isOpen);
	};
	const nc = (e, user) => {
		e.preventDefault();
		e.stopPropagation();
		newComment(user);
	};
	const isCurrentUser = currentUser.email === user.email;

	const totalLikeCount = applauds.reduce((acc, cur) => {
		return acc + parseInt(cur.like_count);
	}, 0);

	return (
		<div
			ref={userCardRef}
			id={`${user.email}`}
			className={`user-card ${isOpen ? 'm-open' : ''} ${
				isCurrentUser ? 'current-user' : ''
			} `}
			onClick={toggle}
		>
			<p className={`user-name`}>
				@{user.last_name} {user.first_name}
			</p>
			<div className='user-comments'>
				{!isOpen && <Applaud comment={latestComment} showTime={false} />}
				{isOpen && (
					// <Suspense fallback={<Spinner />}>
					<ul className=''>
						{applauds.map((comment) => (
							<li key={`message-${comment.id}`}>
								<Applaud comment={comment} showTime />
							</li>
						))}
					</ul>
					// </Suspense>
				)}
			</div>
			<div className='card-stats'>
				<div className='card-messagecount'>
					{isOpen && <SpeechbubbleRosaImg />}
					{!isOpen && <SpeechbubbleWhiteImg />}
					{applauds.length || '--'}
					{!!totalLikeCount && (
						<>
							{'   '}
							<HeartFullImg /> {totalLikeCount || '--'}
						</>
					)}
				</div>
				<div className='card-toggle'>
					<ShowMoreImg />
				</div>
				<div className='card-add' onClick={(e) => nc(e, user)}>
					{!isCurrentUser && (
						<>
							{!isOpen && <AddPinkImg />}
							{isOpen && <AddDarkImg />}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
