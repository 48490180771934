/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';

import { config } from '../config';
import { Link } from 'react-router-dom';
import placeholderImg from '../../images/icon_pods.svg';
import slackImg from '../../images/slack.png';
import joinSlackImg from '../../images/joinslack.svg';
import MainTitle from '../Components/common/MainTitle';
import { TinyContactLink } from './Contacts';
import { apiClient } from '../Helpers/apiCall';

export default function Pods({ podId }) {
	// const [activeNavItem, setActiveNavItem] = useState(0);
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { pods } = state;

	const pod = pods.items.find((i) => parseInt(i.id) === parseInt(podId));

	const sortedPods = (pods?.items ?? []).sort((a, b) => {
		return a.name.localeCompare(b.name, config.localeCompare.locale);
	});

	useEffect(() => {
		if (podId) {
			apiCall.GET_POD(podId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [podId]);

	// console.log('pod', pod);

	return (
		<div>
			<MainTitle>
				Podlings
				{/* <p>( More pod functionality coming in the future. )</p> */}
			</MainTitle>
			{podId && <Pod pod={pod} />}
			{!podId && (
				<section>
					<div className='c-pods'>
						{pods.loading && <Loader />}
						{sortedPods && (
							<ul>
								{sortedPods.map((pod) => {
									return (
										<li key={pod.id}>
											<PodLink pod={pod} />
										</li>
									);
								})}
							</ul>
						)}
					</div>
				</section>
			)}
		</div>
	);
}

export const PodLink = ({
	pod
}) => {
	const baseUrl = '/podlings';
	const hasImg = pod?.thumb_id ?? false;

	const { state } = useStore();
	const podUsers = (state?.users?.items ?? []).filter((u) =>
		// eslint-disable-next-line eqeqeq
		u.pod.some((r) => pod.id == r.id)
	);

	return (
		/* TODO: pod can belong to multiple groups, how to style? */
		<Link className={`c-pods-item`} to={`${baseUrl}/${pod.id}`}>
			<div className='pod-img'>
				{!hasImg && (
					<img
						src={placeholderImg}
						alt='profile'
						loading='lazy'
						className='thumb mini'
					/>
				)}
				{hasImg && (
					<img
						src={config.apiUrl + '/file/' + hasImg + '/preset=person'}
						alt='profile'
						loading='lazy'
						className='thumb mini'
					/>
				)}
			</div>
			<div className='pod-details'>
				<p className='name'>
					<span>{pod.name}</span>
				</p>
				{podUsers.length > 0 &&
					<p>{podUsers.length} members</p>
				}
			</div>
		</Link>
	);
};

export const SlackLink = ({ slackChannel, children }) => {
	return (
		<a
			className='slack'
			target='_blank'
			href={`https://dqc.slack.com/archives/${slackChannel}`}
			rel='noopener'
		>
			{children}
		</a>
	);
}

export const Pod = ({ pod }) => {
	const { state } = useStore();
	if (!pod) {
		return <Loader className=""></Loader>;
	}
	
	// eslint-disable-next-line eqeqeq
	const podUsers = (state?.users?.items ?? []).filter((u) => u.pod.some((r) => pod.id == r.id));
	const hasImg = pod?.thumb_id ?? false;

	return (
		<section id={pod.id} className={`c-page pod-pod `}>
			{/* <BackButton /> */}
			<div className='c-pod'>
				<div className='row'>
					<div className='image'>
						{!hasImg && (
							<>
								<img
									src={placeholderImg}
									alt='profile'
									loading='lazy'
									className='thumb'
								/>
							</>
						)}
						{hasImg && (
							<>
								<img
									src={config.apiUrl + '/file/' + hasImg + '/preset=person'}
									alt='profile'
									loading='lazy'
									className='thumb'
								/>
							</>
						)}

						{pod.slack_channel && (
							<SlackLink slackChannel={pod.slack_channel}>
								<img src={slackImg} alt='Slack' />
								<img src={joinSlackImg} alt="joinslack" className='joinslack' />
							</SlackLink>
						)}
					</div>
					<div className='text'>
						<h2>{pod.name}</h2>

						<div className='description'>
							<p>{pod.description}</p>

							{pod.slack_channel && (
								<SlackLink slackChannel={pod.slack_channel}>
									{/* <span className='icon'></span> */}
									Join the Slack Channel
								</SlackLink>)}
						</div>

						{/* <div>
							<div className='buttons'>
								{pod.slack_channel && (
									<SlackLink slackChannel={pod.slack_channel}>
										<span className='icon'></span>
										Slack
									</SlackLink>
								)}
							</div>
						</div> */}
					</div>
				</div>

				{podUsers.length > 0 && (
					<div className='c-members'>
						<p>
							<strong>{podUsers.length} members:</strong>
						</p>

						<ul className='c-members-list'>
							{podUsers.map((item) => {
								return (
									<li key={item.id}>
										{/* <ContactLink user={item} key={item.id} /> */}
										<TinyContactLink user={item} key={item.id} />
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		</section>
	);
};
