/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../Helpers/apiCall';
import Loader from './Loader';
import { SVG_LOGO } from '../../Helpers/icons';

const EnvInfo = (props) => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	useEffect(() => {
		if (!state.common.env && !state.common.loading) {
			apiCall.GET('info/which-env');
			// apiCall.action("GET_ENV");
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.common]);

	const information = state.common;
	if (!information) return <Loader />;

	return (
		<div className="versionInfo">
			<div>
				{`App: ${process?.env?.REACT_APP_ENV ?? ''}`}
				<br/>
				{information.env &&
					`Env: ${information.env.env} / ${information.env.db}`}
			</div>
		</div>
	);
};

export default function AdminNav({ location }) {
	const { state, dispatch } = useStore();
	// const isActivePath = (path) => !!matchPath(location.pathname, path);

	const navOpenState = !(state?.common?.anMinimized ?? false);

	const toggleNavOpenState = () => {
		dispatch({ type: 'TOGGLE_ADMIN_NAV' });
	};

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	return (
		<div className={`left ${navOpenState ? 'open' : 'minimized'}`}>
			<div className={`nav`}>
				<Link to={'/admin'} className={'logo'}>
					<SVG_LOGO />
				</Link>
				<ul>
					{/* <li>
						<NavLink activeClassName="active" exact to={'/admin'}>
							Dashboard
						</NavLink>
					</li> */}
					<li className="">
						<NavLink activeClassName="active" to={'/admin/feed'}>
							Syöte / Viestit
						</NavLink>
					</li>
					<li className="">
						<NavLink activeClassName="active" to={'/admin/feedback'}>
							Palaute
						</NavLink>
					</li>
					<li className="">
						<NavLink activeClassName="active" to={'/admin/users'}>
							Käyttäjät
						</NavLink>
					</li>
					<li className="">
						<NavLink activeClassName="active" to={'/admin/groups'}>
							Käyttäjäryhmät
						</NavLink>
					</li>
					<li className="">
						<NavLink activeClassName="active" to={'/admin/pods'}>
							Podlings
						</NavLink>
					</li>
					<li className="">
						<NavLink activeClassName="active" to={'/admin/messages'}>
							Lähetyslogi
						</NavLink>
					</li>
					<li className="">
						<NavLink activeClassName="active" to={'/admin/debug'}>
							Testaus
						</NavLink>
					</li>
					<li className="spacer"></li>
					<li className="external">
						<a href={'http://dqcomms.com/wp-admin'} target="_blank">
							Ohjeistus
						</a>
					</li>
					<li className="spacer"></li>

					<li className="external">
						<a href={'/'}>Käyttäjänäkymä</a>
					</li>
					<li className="spacer"></li>
					<li>
						<a className="logout" onClick={logoutAction}>
							Kirjaudu ulos
						</a>
					</li>
				</ul>
			</div>
			<button className={'toggle'} onClick={toggleNavOpenState}>
				{' '}
				«»{' '}
			</button>
			<EnvInfo />
		</div>
	);
}
