import React from 'react';
import { useStore } from '../../../store/useStore';
import { ConditionalWrapper } from '../../Helpers/common';
import { Link } from 'react-router-dom';

export default function UserName(props) {
	const { state } = useStore();
	const { user_id, useLink } = props;

	const user = state?.users?.items.find((item) => {
		return item.id === user_id;
	});

	if (!user) {
		return <></>;
	}
	return (
		<ConditionalWrapper
			condition={useLink}
			wrapper={(children) => (
				<Link to={`/admin/user/${user.id}`}>{children}</Link>
			)}
		>
			<span>
				{user.first_name} {user.last_name}
			</span>
		</ConditionalWrapper>
	);
}
