import React, { useEffect, useState } from 'react';
import { config } from '../config';
import { apiClient } from '../Helpers/apiCall';
// import { GlobalStore } from '../store';
// import { fetchUsersIfNeeded } from '../Reducers/UsersReducer';
// import { fetchCommentsIfNeeded } from '../Reducers/CommentsReducer';

// import ListPage from './ListPage';
// import CommentModalPage from './CommentModalPage';
// import Spinner from './Spinner';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import MainTitle from '../Components/common/MainTitle';
import ApplaudsList from '../Components/ApplaudsList';
import { dateParser } from '../Components/common/Timestamp';
import ApplaudForm from '../Components/ApplaudForm';
import { CloseImg2 } from '../../images/draamattupiiri_icons/icons';
import UserSearch from '../Components/UserSearch';
import { HashLink } from 'react-router-hash-link';
// import { useLocation } from 'react-router-dom';

export default function Draamattupiiri() {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	const [userListOpen, setUserListOpen] = useState(false);

	const {
		applauds: { modalOpen },
		user: { data: currentUser },
	} = state;

	const setModalOpen = (payload) => {
		setUserListOpen(false);
		dispatch({ type: 'APPLAUDS_MODAL', payload: payload });
	};

	const fetchX = (key) => {
		if (!state[key].loading) {
			dispatch({ type: `${key.toUpperCase()}_LOADING` });
			apiCall.GET(`${key}`);
		}
	};

	const requiredData = [
		'users',
		'applauds',
	];


	const { users, applauds } = state;

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isMounted = true;
		requiredData.forEach((item) => {
			fetchX(item);
		});

		dispatch({ type: `${`applauds/my-likes`.toUpperCase()}_LOADING` });
		apiCall.GET(`applauds/my-likes`);

		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const userCards =
		users.items &&
		users.items.map((item) => {
			return {
				user: item,
				applauds: [
					...applauds.applauds.filter((a) => a.to_id === item.id),
				],
			};
		});

	const visibleUserCards = userCards.filter((item) => item.applauds.length > 0);
	visibleUserCards.sort((a, b) => {
		let a_c = [...a.applauds].shift();
		let b_c = [...b.applauds].shift();
		let a_v = a_c !== undefined ? a_c.created_at : 0;
		let b_v = b_c !== undefined ? b_c.created_at : 0;

		return a_v < b_v ? (a_v === b_v ? 0 : 1) : -1;
	});

	const sortedUsers = ([...visibleUserCards] ?? []).sort((a, b) => {
		return a.user.last_name.localeCompare(b.user.last_name, config.localeCompare.locale);
	});
	const sortedAllUsers = (users?.items ?? []).sort((a, b) => {
		return a.last_name.localeCompare(b.last_name, config.localeCompare.locale);
	});



	const today = new Date();
	const compareDateYTD = new Date(today.getFullYear(), 0, 1);
	
	const compareDateMonth = new Date(today.getFullYear(), today.getMonth(), 1);
	
	const compareDateWeek = new Date(
		today.setDate(today.getDate() - today.getDay())
		);
		
	// const compareDateEndYTD = today;
	// const compareDateEndMonth = today;
	// const compareDateEndWeek = today;

	const eligibleCommentsYTD = 
		[...applauds.applauds].filter(
			(a) =>
				dateParser(a.created_at) > compareDateYTD
				// &&
				// dateParser(a.created_at) < compareDateEndYTD
		);

	
	const eligibleCommentsMonth = 
		[...applauds.applauds].filter(
			(a) =>
				dateParser(a.created_at) > compareDateMonth
				// &&
				// dateParser(a.created_at) < compareDateEndMonth
		);
	
	const eligibleCommentsWeek = 
		[...applauds.applauds].filter(
			(a) =>
				dateParser(a.created_at) > compareDateWeek
				// &&
				// dateParser(a.created_at) < compareDateEndWeek
		);

	const StatAllTime = applauds.applauds.length ?? 0;
	const StatThisYear = eligibleCommentsYTD.length ?? 0;
	const StatThisMonth = eligibleCommentsMonth.length ?? 0;
	const StatThisWeek = eligibleCommentsWeek.length ?? 0;


	const isLoading = users.loading || applauds.loading;


	return (
		<div className='c-draamattupiiri'>
			{!modalOpen && <MainTitle>Applauds</MainTitle>}
			{isLoading && <Loader />}
			{!isLoading && modalOpen && (
				<CommentModalPage
					closeModalFn={() => setModalOpen(false)}
					to={modalOpen}
				/>
			)}
			{!isLoading && !modalOpen && (
				<div className='widelayout'>
					<section className='user-list'>
						<ul className='c-users-list'>
							{sortedUsers.map((a) => {
								const i = a.user;
								return (
									<li key={i.email}>
										<HashLink smooth to={`#${i.email}`}>
											{i.last_name} {i.first_name}
										</HashLink>
									</li>
								);
							})}
						</ul>
					</section>
					<section>
						<UserSearch
							users={sortedAllUsers}
							currentUser={currentUser}
							openCommentModal={setModalOpen}
						/>

						{!userListOpen && (
							<ApplaudsList
								newComment={setModalOpen}
								visibleUserCards={visibleUserCards}

							/>
						)}

						{/* <button onClick={loadMore}>Lataa uusimmat viestit</button> */}
					</section>
					<section className='stat-column'>
						<div>
							<StatDisplay value={StatAllTime} label={'All-time compliments'} />
							<StatDisplay value={StatThisYear} label={'This year'} />
							<StatDisplay value={StatThisMonth} label={'This month'} />
							<StatDisplay value={StatThisWeek} label={'This week'} />
						</div>
					</section>
				</div>
			)}
		</div>
	);

}

const StatDisplay = ({ label, value }) => {
	return (
		<div className="c-stat">
			<div className='stat-value'>{value}</div>
			<div className='stat-label'>{label}</div>
		</div>
	)
}

const CommentModalPage = (props) => {
	const { to, children, closeModalFn } = props;
	const { state } = useStore();
	
	const {
		user: { data: currentUser },
		applauds: commentsState,
	} = state;

	const commentsByYou = commentsState.applauds.filter(
		a => a.from_id === currentUser.id
	);
	const closeModal = e => {
		e.preventDefault();
		e.stopPropagation();
		closeModalFn();
		// dispatch(closeCommentModal());
	};

	return (
		<div className='page comment-modal'>
			<button onClick={closeModal} className='close-modal'>
				<CloseImg2 />
			</button>
			<ApplaudForm
				to={to}
				showSkip={props.skipFn || false}
				bigName={children ? false : true}
				step={commentsByYou.length || 1}
			/>

			{/* <p className="debug">
				- Kommentteja: {commentsByYou.length} / {appSettings.minimumComments} -
			</p> */}
		</div>
	);
}

