// import { toast } from 'react-toastify';
import { replaceOrAddById } from '../App/Helpers/store';

export const FETCH_APPLAUDS = 'FETCH_APPLAUDS';
export const FETCH_APPLAUDS_FAIL = 'FETCH_APPLAUDS_FAIL';
export const FETCH_APPLAUDS_SUCCESS = 'FETCH_APPLAUDS_SUCCESS';
export const FETCH_USERS_CACHE = 'FETCH_USERS_CACHE';
export const ADD_APPLAUD = 'ADD_APPLAUD';
export const ADD_APPLAUD_FAIL = 'ADD_APPLAUD_FAIL';
export const ADD_APPLAUD_SUCCESS = 'ADD_APPLAUD_SUCCESS';
export const LIKE_APPLAUD = 'LIKE_APPLAUD';
export const LIKE_APPLAUD_FAIL = 'LIKE_APPLAUD_FAIL';
export const LIKE_APPLAUD_SUCCESS = 'LIKE_APPLAUD_SUCCESS';

/** ACTIONS */
export const applaudsInitialState = {
	loading: false,
	lastUpdate: undefined,
	lastApplaud: false,
	modalOpen: false,
	applauds: [],
	likes: []
};

// export const replaceOrAddById = (items, replace) => {
// 	for (let i = 0; i < items.length; i++) {
// 		if (parseInt(items[i].id) === parseInt(replace.id)) {
// 			items[i] = replace;
// 			return items;
// 		}
// 	}
// 	items.push(replace);
// 	return items;
// };

// export function fetchApplaudsIfNeeded(state_in) {
// 	return async function (dispatch, state) {
// 		if (state.users.loading) {
// 			dispatch({ type: FETCH_USERS_CACHE });
// 		} else {
// 			dispatch({ type: FETCH_APPLAUDS });
// 			try {
// 				console.log('this be wrong', state.auth);
// 				const res = await fetchApi('/applauds', state_in);
// 				dispatch({ type: FETCH_APPLAUDS_SUCCESS, payload: res });
// 			} catch (error) {
// 				dispatch({ type: FETCH_APPLAUDS_FAIL, payload: error });
// 			}
// 		}
// 	};
// }

// export function addApplaud(from, to, message, state_in) {
// 	return async function (dispatch, state) {
// 		dispatch({
// 			type: ADD_APPLAUD,
// 		});
// 		try {
// 			const res = await postApi('/applaud', { from, to, message }, state_in);
// 			dispatch({ type: ADD_APPLAUD_SUCCESS, payload: res });
// 			dispatch({ type: CLOSE_APPLAUD_MODAL });
// 		} catch (error) {
// 			dispatch({ type: ADD_APPLAUD_FAIL, payload: error });
// 		}
// 	};
// }

// export function setApplaudLike(from, id, status, state_in) {
// 	return async function (dispatch, state) {
// 		dispatch({
// 			type: LIKE_APPLAUD,
// 		});
// 		try {
// 			const res = await postApi('/like', { from, id, status }, state_in);
// 			dispatch({ type: LIKE_APPLAUD_SUCCESS, payload: res });
// 		} catch (error) {
// 			dispatch({ type: LIKE_APPLAUD_FAIL, payload: error });
// 		}
// 	};
// }

/** REDUCER */

export const applaudsReducer = (state = applaudsInitialState, action) => {
	switch (action.type) {
		case 'APPLAUDS_LOADING':
			return {
				...state,
				loading: true,
			};
		case 'APPLAUDS_GET':
			return {
				...state,
				loading: false,
				modalOpen: false,
				applauds: [...action.payload.data.data].sort((a, b) => {
					return a.created_at < b.created_at
						? a.created_at === b.created_at
							? 0
							: 1
						: -1;
				}),
			};
		case 'LIKE_APPLAUD_POST':
		case 'APPLAUDS/MY-LIKES_GET':
			return {
				...state,
				likes: [...action.payload.data.data],
			};

		case 'APPLAUDS/MY-LIKES_ERROR':
		case 'APPLAUDS_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'APPLAUDS_ADD':
		case 'APPLAUDS_DELETE':
			return {
				...state,
				loading: true,
				modalOpen: false
			};
		case 'APPLAUDS_MODAL':
			return {
				...state,
				modalOpen: action.payload,
			};

		case 'APPLAUD_GET':
			const applauds = replaceOrAddById(
				[...state.applauds],
				action.payload.data.data
			);

			return {
				...state,
				applauds,
				loading: false,
			};

		// case LIKE_APPLAUD:
		// 	return {
		// 		...state,
		// 	};
		// case ADD_APPLAUD_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		lastApplaud: action.payload.result.added,
		// 		applauds: [...action.payload.result.applauds],
		// 	};
		// case LIKE_APPLAUD_SUCCESS:
		// 	const items = replaceOrAddById(
		// 		[...state.applauds],
		// 		action.payload.result.applaud
		// 	);

		// 	return {
		// 		...state,
		// 		loading: false,
		// 		// lastApplaud: action.payload.result.added,
		// 		// applauds: [...action.payload.result.applauds],
		// 		applauds: [...items],
		// 	};
		// case ADD_APPLAUD_FAIL:
		// case LIKE_APPLAUD_FAIL:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 	};
		// case FETCH_USERS_CACHE:
		default:
			return state;
	}
};
