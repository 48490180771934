import React, { useState, useRef, useEffect } from 'react';

export default function UserSearch({ openCommentModal, users, currentUser }) {

	const inputRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [searchField, setSearchField] = useState('');
	const toggle = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (isOpen && inputRef?.current) {
			inputRef.current.focus();
		}
	}, [inputRef, isOpen]);

	return (
		<div className={`search ${isOpen ? 'open' : 'not-open'}`}>
			<button onClick={toggle} className='m-notabtn'>
				Leave a compliment
			</button>
			{isOpen && (
				<input
					type='text'
					placeholder='Search'
					value={searchField}
					onChange={(e) => setSearchField(e.target.value)}
					ref={inputRef}
				/>
			)}
			{isOpen && (
				<ul>
					{users && 
							// sort user list by last name							
							users.filter(
								(i) =>
									i.email.includes(searchField) ||
									i.first_name.includes(searchField) ||
									i.last_name.includes(searchField)
							)
							.map((user) => {
								return user.email !== currentUser.email ? (
									<li
										key={user.email}
										onClick={() => openCommentModal(user)}
										className='search-user'
									>
										<p>
											<strong>{user.last_name}</strong> {user.first_name}
										</p>
									</li>
								) : (
									''
								);
							})}
				</ul>
			)}
		</div>
	);
}
