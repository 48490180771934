/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
	useTable,
	useSortBy,
	// useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
} from 'react-table';

import { ICON_FILTER_ARROW_IMG } from '../../Helpers/icons';
import Loader from './Loader';

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		);
	}
);

// // This is a custom filter UI for selecting
// // a unique option from a list
// export const SelectColumnFilter = ({
// 	column: { filterValue, setFilter, preFilteredRows, id },
// }) => {
// 	// Calculate the options for filtering
// 	// using the preFilteredRows
// 	const options = React.useMemo(() => {
// 		const options = new Set();
// 		preFilteredRows.forEach((row) => {
// 			options.add(row.values[id]);
// 		});
// 		return [...options.values()];
// 	}, [id, preFilteredRows]);

// 	// Render a multi-select box
// 	return (
// 		<select
// 			value={filterValue}
// 			onChange={(e) => {
// 				setFilter(e.target.value || undefined);
// 			}}
// 		>
// 			<option value="">All</option>
// 			{options.map((option, i) => (
// 				<option key={i} value={option}>
// 					{option}
// 				</option>
// 			))}
// 		</select>
// 	);
// };

export default function CustomTable(props) {
	const {
		columns,
		data,
		hidePagination,
		config,
		passSelectedRowIds,
		useSelect,
	} = props;
	const onRowClick = props.onRowClick ? props.onRowClick : () => {};

	const tableConfig = [
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: config?.pageSize ?? 12,
				globalFilter: props.filter || '',
			},
		},
		// useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
	];

	if (useSelect) {
		tableConfig.push(useRowSelect);
		tableConfig.push((hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			]);
		});
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		// rows,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		selectedFlatRows,
		state: { pageIndex, pageSize, selectedRowIds },
	} = useTable(...tableConfig);

	useEffect(() => {
		if (useSelect) {
			if (passSelectedRowIds) {
				passSelectedRowIds.current = { selectedRowIds, selectedFlatRows };
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRowIds]);

	if (!data) {
		return <Loader />;
	}

	if (data.length === 0) {
		return (
			<div>
				<p>...</p>
			</div>
		);
	}

	return (
		<div className="table-wrapper">
			<table
				{...getTableProps()}
				className={`ReactTable ${props.className ? props.className : ''}`}
				cellPadding={0}
				cellSpacing={0}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps({
										...column.getSortByToggleProps(),
										className: column.id,
									})}
								>
									{column.render('Header')}
									<span
										className={
											column.isSorted
												? column.isSortedDesc
													? 'sorted desc'
													: 'sorted asc'
												: 'unsorted'
										}
									>
										<ICON_FILTER_ARROW_IMG />
									</span>
									<div
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
										}}
									>
										{column.Filter ? column.render('Filter') : null}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps({
									onClick: (event) => {
										onRowClick(row);
									},
								})}
							>
								{row.cells.map((cell) => {
									return (
										<td
											{...cell.getCellProps({
												className: cell.column.id,
											})}
										>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			{!hidePagination && (
				<div className="pagination">
					{pageCount > 1 && (
						<div className="buttons">
							<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{'«'}
							</button>{' '}
							<button
								onClick={() => previousPage()}
								disabled={!canPreviousPage}
							>
								{'‹'}
							</button>{' '}
							<button onClick={() => nextPage()} disabled={!canNextPage}>
								{'›'}
							</button>{' '}
							<button
								onClick={() => gotoPage(pageCount - 1)}
								disabled={!canNextPage}
							>
								{'»'}
							</button>{' '}
						</div>
					)}
					<span>
						Sivu{' '}
						<strong>
							{pageIndex + 1} / {pageOptions.length}
						</strong>{' '}
					</span>
					{/* 
						<span>
							| Go to page:{" "}
							<input
								type="number"
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									gotoPage(page);
								}}
								style={{ width: "100px" }}
							/>
						</span>{" "}
						<select
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
							}}
						>
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select> 
					*/}
				</div>
			)}
			{/* <CustomControls /> */}
		</div>
	);
}
