import React, { useRef } from 'react';
import { useStore } from '../../store/useStore';
import { apiClient } from '../Helpers/apiCall';
import { SpeechbubbleWhiteImg } from '../../images/draamattupiiri_icons/icons';
// import { addComment } from '../Reducers/CommentsReducer';


export default function ApplaudForm(props) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { to } = props;
	const {
		applauds: commentsState,
	} = state;

	const messageElement = useRef(null);
	const isLoadingOrSubmitting = commentsState.isLoading;

	const onsubmit = (event) => {
		event.preventDefault();

		if (!messageElement.current.value) {
			console.warn('Cannot submit, message empty');
			return false;
		}
		if (isLoadingOrSubmitting) {
			console.warn('Cannot submit, already submitting?');
			return false;
		} else {

			// eslint-disable-next-line no-unused-vars
			const res = apiCall
				.ADD(`applauds`, {
					to_id: to.id,
					message: messageElement.current.value
				})
				.then((response) => {
					//refetch applauds
					if (response.status === 200) {
						apiCall.GET('applauds');
					}
				});

			messageElement.current.value = '';
		}
	};

	const placeholderMessages = [
		'Only write good things here. You can write in Finnish, Swedish or English.',
		// 'Kirjoita tähän vain hyvänmielen juttuja...',
		// 'Kirjoita viestisi tähän, esim. ”Toimiston hauskimmat läpät”',
		// 'Kirjoita viestisi tähän, esim. ”Aina liekeissä!!🔥🔥🔥”',
		// 'Kirjoita viestisi tähän, esim. ”Luottotyyppi. Saa asiat aina hoitumaan. 😊”',
	];

	const placeholderMsg =
		placeholderMessages[Math.floor(Math.random() * placeholderMessages.length)];

	if (to === undefined) {
		return <div>...</div>;
	}



	return (
		<div className='comment-form'>
			<form
				onSubmit={onsubmit}
				className={isLoadingOrSubmitting ? 'disabled' : ''}
			>
				<h3>
					<SpeechbubbleWhiteImg />
					<br />
					<br />
					Leave a praise or thanks to{' '}
					<span>
						{to.last_name} {to.first_name}
					</span>
				</h3>
				<textarea placeholder={placeholderMsg} ref={messageElement}></textarea>
				<br/>
				<button disabled={isLoadingOrSubmitting} className='c-button rosa'>
					Publish
				</button>
				{/* {props && props.showSkip && typeof props.showSkip === 'function' && (
					<button className='m-notabtn' type='button' onClick={props.showSkip}>
						Hetkinen, tämä tyyppi ei kuulosta tutulta. Näytä seuraava. ›
					</button>
				)} */}
			</form>
		</div>
	);
}
