import React from 'react';
import { useStore } from '../../../store/useStore';
import { isAdmin } from '../../Helpers/common';

export default function UserRole({ userId }) {
	const { state } = useStore();

	const user = state?.users?.items.find((item) => {
		return item.id === userId;
	});

	if (!user) {
		return <></>;
	}
	const userIsAdmin = isAdmin(user);
	return <span>{userIsAdmin ? 'Ylläpitäjä' : 'Normaali käyttäjä'}</span>;
}
