import React, { useEffect } from 'react';
import { useStore } from '../../store/useStore';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import Loader from './common/Loader';


export default function IGFeed() {
	const { state, dispatch } = useStore();

	const fetchFlocklerFeed = async () => {
		if (!state.igfeed.loading) {
			dispatch({ type: 'IGFEED_LOADING' });

			// const SITE_UUID = '01h3cqe975srrvf1zwp96xs29x';
			const SITE_UUID = '1727a39bade05e1aaa59c19129515df9';
			const API_KEY = '0ad14bdc5291a3e84e5e1babef05db09';

			try {
				const res = await fetch(
					`https://api.flockler.app/v2/${SITE_UUID}/posts?filterBySectionIds[]=76010`,
					{
						headers: {
							'X-FL-API-Key': API_KEY,
						},
					}
				);

				if (res?.status === 200) {
					const data = await res.json();
					dispatch({ type: 'IGFEED_GET', payload: { data: data } });
					return true;
				}
			} catch (e) {}

			dispatch({ type: 'IGFEED_ERROR' });
			return false;
		}
	};

	useEffect(() => {
		if ((state?.igfeed?.items?.length ?? 0) < 1) fetchFlocklerFeed();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const latestPost = state?.igfeed?.items[0] ?? false;
	const latestPosts = state?.igfeed?.items
		? state.igfeed.items.slice(0, 5)
		: [];

	// console.log('igfeed', state.igfeed);
	// console.log('igfeed', latestPost);

	// <Carousel settings={newsettings} endOnboarding={endOnboarding} setIndex={setIndex} />

	const sliderSettings = {
		lazyload: false,
		loop: true,
		nav: true,
		gutter: 0,
		controls: false,
		arrowKeys: false,
		mouseDrag: true,
		// speed: 1000,
		autoPlay: true,
		autoplayTimeout: 1000,
		autoplayHoverPause: false,
		navPosition: 'bottom',
	};

	return (
		<div className='c-ig-feed'>
			{state.igfeed.loading && <Loader />}
			{!state.igfeed.loading && latestPosts.length > 0 && <IGSlider sliderSettings={sliderSettings} latestPosts={latestPosts} />}
			<label>
				<Link to={`https://www.instagram.com/dramaqueencomms/`}>
					Latest from @dramaqueencomms
				</Link>
			</label>
		</div>
	);
}

const IGSlider = ({ sliderSettings, latestPosts }) => {
	// const sliderRef = useRef(null);

	const Posts = latestPosts.map((i) => (
		<a
			href={i.sourceUrl}
			target='_blank'
			rel='noopener noreferrer'
			key={i.flocklerId}
		>
			<img src={i.images[0].url} alt={i.images[0].altText} />
			{/* <p className='' dangerouslySetInnerHTML={{ __html: i.textPlain }}></p> */}
		</a>
	));

	return (
		<TinySlider settings={sliderSettings}>
			{ Posts }	
		</TinySlider>
	);
};
