/* eslint-disable eqeqeq */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ICON_TRASH_IMG, ICON_DUPLICATE_IMG } from '../../Helpers/icons';

import Loader from '../../Components/common/Loader';

const GenericSortable = ({
	label,
	field,
	data,
	handleOnChange,
	EditableComponent,
	sendFile,
	onRemove,
	template,
	addNewLabel,
}) => {
	if (!data) {
		return <Loader />;
	}

	const rawItems = typeof data[field] !== 'undefined' ? [...data[field]] : [];

	const addItem = (duplicateItem) => {
		const newItem = duplicateItem
			? { ...duplicateItem, id: createId() }
			: {
					id: createId(),
			  };
		const items = replaceOrAdd([...rawItems], newItem);

		handleOnChange({
			name: field,
			value: items,
		});
	};

	const removeItem = (removeId) => {
		if (window.confirm('Haluatko varmasti poistaa tämän?')) {
			const items = [...rawItems].filter((i) => {
				return i.id != removeId;
			});

			handleOnChange({
				name: field,
				value: items,
			});
		}
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const items = reorder(
			rawItems,
			result.source.index,
			result.destination.index
		);

		handleOnChange({ name: field, value: items });
	};

	const editItemData = (event, id) => {
		const { name, value } = event.target;

		const existingItem = rawItems.find((item) => {
			return item.id == id;
		});
		existingItem[name] = value;
		const items = replaceOrAdd([...rawItems], existingItem);

		handleOnChange({
			name: field,
			value: items,
		});
	};

	// const sendFile = (file, cb) => {
	// 	if (file.size > config.maxFileSize) {
	// 		alert('Tiedoston maksimikoko on 10MB');
	// 		return false;
	// 	}
	// 	return apiCall.UPLOAD('files', file).then((response) => {
	// 		if (response.status === 200 && response.data && response.data.data) {
	// 			cb({
	// 				target: { name: 'thumb_id', value: response.data.data.id },
	// 			});
	// 		}
	// 	});
	// };

	// const contentEdit = (event) => {
	// 	const { name, value } = event.target;
	// 	item.content[name] = value;

	// 	const newEvent = { target: { name: 'content', value: item.content } };
	// 	handleOnChange(newEvent, item.id);
	// };

	const className = 'generic-drag-drop';
	const addNewLabelTxt = addNewLabel ? addNewLabel : 'Lisää sisältöä';

	return (
		<div>
			{label && <label className="label">{label}</label>}
			<div className={`${className} drag-and-drop`}>
				<ul className={'content-carousel'}>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="list" direction="vertical">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									className="droppable-area"
								>
									<ItemList
										items={rawItems}
										sendFile={sendFile}
										addItem={addItem}
										onRemove={removeItem}
										handleOnChange={editItemData}
										EditableComponent={EditableComponent}
									/>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</ul>
				<div className={'add'}>
					<button onClick={() => addItem()} className="button" type="button">
						{addNewLabelTxt}
					</button>
				</div>
			</div>
		</div>
	);
};

export default GenericSortable;

const ItemList = React.memo(function ItemList(props) {
	const items = props.items;
	if (!items) return <Loader />;
	return items.map((item, index) => (
		<Item item={item} index={index} key={item.id} {...props} />
	));
});

const Item = (props) => {
	const showActions = true;
	const { EditableComponent, onRemove, addItem } = props;

	const item = props.item;
	const index = props.index;

	return (
		<Draggable draggableId={`drag-section-${item.id}`} index={index}>
			{(provided, snapshot) => (
				<li
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="drag-item"
					style={getItemStyle(
						snapshot.isDragging,
						provided.draggableProps.style
					)}
					key={item.id}
				>
					<span className="drag-bar">|||</span>
					<EditableComponent {...props} />

					{showActions && (
						<div className="actions">
							<span
								className="remove"
								onClick={() => onRemove(item.id)}
								type="button"
							>
								<ICON_TRASH_IMG />
							</span>
							<span
								className="remove"
								onClick={() => addItem(item)}
								type="button"
							>
								<ICON_DUPLICATE_IMG />
							</span>
						</div>
					)}
				</li>
			)}
		</Draggable>
	);
};

const getItemStyle = (isDragging, draggableStyle) => ({
	...draggableStyle,
});

const createId = () => {
	return '_' + Math.random().toString(36).substr(2, 9);
};

const replaceOrAdd = (items, replace) => {
	for (let i = 0; i < items.length; i++) {
		if (items[i].id == replace.id) {
			items[i] = replace;
			return items;
		}
	}
	items.push(replace);
	return items;
};

const reorder = (list, startIndex, endIndex) => {
	let result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
