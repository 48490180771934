import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

import { commonInitialState, commonReducer } from './common';
import { userInitialState, userReducer } from './user';
import { usersInitialState, usersReducer } from './users';
import { feedInitialState, feedReducer } from './feed';
import { feedbackInitialState, feedbackReducer } from './feedback';
import { pagesInitialState, pagesReducer } from './pages';
import { messagesInitialState, messagesReducer } from './messages';
// import { translationsInitialState, translationsReducer } from './translations';
import { groupsInitialState, groupsReducer } from './groups';
import { ackInitialState, ackReducer } from './ack';
import { igfeedInitialState, igfeedReducer } from './igfeed';
import { applaudsInitialState, applaudsReducer } from './applauds';
import { weeklyInitialState, weeklyReducer } from './weekly';
import { podsInitialState, podsReducer } from './pods';

const initialState = {
	common: commonInitialState,
	user: userInitialState,
	users: usersInitialState,
	pages: pagesInitialState,
	feed: feedInitialState,
	feedback: feedbackInitialState,
	messages: messagesInitialState,
	// translations: translationsInitialState,
	groups: groupsInitialState,
	ack: ackInitialState,
	igfeed: igfeedInitialState,
	applauds: applaudsInitialState,
	weekly: weeklyInitialState,
	pods: podsInitialState,
};

const StoreContext = createContext(initialState);

const reducer = (state, action) => {
	const {
		common,
		user,
		users,
		pages,
		weekly,
		feed,
		feedback,
		messages,
		// translations,
		groups,
		ack,
		igfeed,
		applauds,
		pods
	} = state;

	console.log('REDUCER ACTION :: ', action.type);

	const currentState = {
		common: commonReducer(common, action),
		user: userReducer(user, action),
		users: usersReducer(users, action),
		pages: pagesReducer(pages, action),
		weekly: weeklyReducer(weekly, action),
		feed: feedReducer(feed, action),
		feedback: feedbackReducer(feedback, action),
		messages: messagesReducer(messages, action),
		// translations: translationsReducer(translations, action),
		groups: groupsReducer(groups, action),
		ack: ackReducer(ack, action),
		igfeed: igfeedReducer(igfeed, action),
		applauds: applaudsReducer(applauds, action),
		pods: podsReducer(pods, action),
	};

	return currentState;
};

export const StoreProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<StoreContext.Provider value={{ state, dispatch }}>
			{children}
		</StoreContext.Provider>
	);
};

StoreProvider.propTypes = {
	children: PropTypes.node,
};

export const useStore = (store) => {
	const { state, dispatch } = useContext(StoreContext);
	return { state, dispatch };
};
