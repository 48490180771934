import React from 'react';
import { useStore } from '../../../store/useStore';

export default function UserGroup({ groupId }) {
	const { state } = useStore();

	const group = state?.groups?.items.find((item) => {
		return item.id === groupId;
	});

	if (!group) {
		return <></>;
	}

	return <span className={`group user-group-${group.id}`}>{group.name}</span>;
}


export const UserGroups = ({ groups}) => {	
	// eslint-disable-next-line eqeqeq
	if (!groups || groups?.length == 0) {
		return <></>;
	}


	return (
		<span className="groups">
			{groups.map((i) => {
				return (
					<UserGroup key={i.id} groupId={i.id} />
				); 
			})}
		</span>
	);
};