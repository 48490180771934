import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';

import { useStore } from '../../../store/useStore';
import { apiClient } from '../../Helpers/apiCall';
import { NavTab } from 'react-router-tabs';

// import {
// 	CustomInput,
// 	CustomSwitch,
// 	CustomSelect,
// 	CustomPictureSelector,
// 	CustomRichTextArea,
// 	createHandlers,
// 	CustomCheckbox,
// } from '../../Components/common/CustomInput';

import Loader from '../../Components/common/Loader';
import UserName from '../../Components/common/UserName';

export default function FeedbackItem(props) {
	const { state, dispatch } = useStore();
	// const { feedbackitem: currentFeedbackItem } = state;
	const apiCall = apiClient(state, dispatch);
	const [data, setData] = useState({});
	const [redirect, setRedirect] = useState(false);
	// const formRef = useRef();
	const { id, match } = props;

	// const ENDPOINT_SINGULAR = 'feedback';
	const ENDPOINT_PLURAL = 'feedback';

	useEffect(() => {
		let isMounted = true;
		if (id) {
			apiCall.GET_FEEDBACKITEM(id).then((response) => {
				if (response.status === 200) {
					if (isMounted) {
						setData({
							...response.data.data,
						});
					}
				}
			});
		}
		return () => {
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

/* 	const {
		handleOnChangeForCheckBoxes,
		handleOnChange,
		handleOnCustomChange,
		handleOnChangeArray,
		handleSwitch,
		handleOnCKEditorChange,
		sendFile,
		sendMedia,
	} = createHandlers(data, setData, apiCall, state); */

	// const customErrors = [];

	/* const onSubmit = (e) => {
		const form = formRef.current;
		const isValid = form && form.checkValidity();
		const isCustomValid = customErrors.length === 0;

		if (id) {
			if (isValid) {
				e.preventDefault();
				if (isCustomValid) {
					var saveData = { ...data };
					apiCall.EDIT(ENDPOINT_PLURAL, id, saveData).then((response) => {
						if (response.status === 200) {
							apiCall.GET_FEEDBACKITEM(data.id);
						}
					});
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		}
	}; */

	const remove = () => {
		if (window.confirm('Haluatko varmasti poistaa tämän?')) {
			apiCall.DELETE(ENDPOINT_PLURAL, data.id).then(() => {
				apiCall.GET(ENDPOINT_PLURAL);
				setRedirect(true);
			});
		}
	};

	if (redirect === true) {
		return <Redirect to={`/admin/${ENDPOINT_PLURAL}`} />;
	}

	if ((id && !data.id) || state.feed.loading || data.loading) {
		return <Loader className="dark"></Loader>;
	}

	return (
		<div className="company-form">
			<header>
				<div className="inner">
					<div className="top">
						<h1>
							<span className="category">
								<Link to={'/admin/feedback'} className="">
									Palaute /
								</Link>
								#{data.id}
							</span>
							Palaute
						</h1>
					</div>
				</div>
			</header>
			<div className="header-bottom">
				<nav>
					<ul className="max-width">
						<li>
							<NavTab to={`${match.url}/info`}>Yleisinfo</NavTab>
						</li>
					</ul>
				</nav>
			</div>

			<Switch>
				<Route
					exact
					path={`${match.path}`}
					render={() => <Redirect replace to={`${match.url}/info`} />}
				/>

				<Route exact path={`${match.url}/info`}>
					<section>
						<fieldset>
							<legend>Palaute</legend>
							{/* <div
								className="html-content"
								dangerouslySetInnerHTML={{
									__html: textWithLineBreaks(data.message) ?? '',
								}}
							></div> */}
							<textarea
								className="html-content"
								value={data.message}
								readOnly
							></textarea>
						</fieldset>
						<fieldset>
							<legend>Käyttäjä</legend>

							{data.author_user_id && (
								<UserName user_id={data.author_user_id} useLink={true} />
							)}
							{!data.author_user_id && <p>Anonyymi palaute</p>}
						</fieldset>

						<fieldset>
							<legend>Toiminnot</legend>
							{data.id && (
								<button
									type="button"
									className="button delete"
									onClick={remove}
								>
									Poista?
								</button>
							)}
							{/* {data.id && (
								<button
									type="button"
									className="button delete"
									onClick={remove}
								>
									Lähetä?
								</button>
							)} */}
						</fieldset>
					</section>
				</Route>
			</Switch>

			{/* <div className="submit-holder">
				<button
					type="submit"
					className="button"
					form="place-form"
					onClick={onSubmit}
				>
					{data.loading && <Loader></Loader>}
					{data.loading ? '' : data.id ? 'Tallenna muutokset' : 'Lisää kohde'}
				</button>
			</div> */}
		</div>
	);
}
