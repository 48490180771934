export const getRequiredFields = (data) => {
	var fields = [
		// "name",
		// "location",
		// "phone",
		// "www",
		// "category",
		// "thumb_id",
		// "category",
		'name',
		'price',
		'price_min_units',
		'price_max_units',

		'invoice_vat_number',
		'address',
		'postal_code',
		'city',
		'www',

		'invoice_type',
		// 'description',
		// "show_opentimes",
		// "is_paid",
		// "is_published",
		// "ig_account",
		// "fb_account",
		'thumb_id',
		// "contact_user_id",
		// "message",
	];

	return fields;
};

export const translateError = (error) => {
	const names = {
		name: 'Nimi',
		name_se: 'Nimi (SE)',
		name_en: 'Nimi (EN)',
		description: 'Kuvaus',
		description_se: 'Kuvaus (SE)',
		description_en: 'Kuvaus (EN)',
		www: 'WWW-osoite',
		category: 'Kategoriat',
		location: 'Sijainti',
		thumb_id: 'Kuva',
		package_id: 'Pakettityyppi',
	};
	return names[error] ? names[error] : error;
	// return names[error] ? names[error] : "Virhe";
};

export const getErrors = (data) => {
	const fields = getRequiredFields(data);
	const errors = fields.reduce((acc, cur) => {
		if (!data[cur]) {
			acc.push(cur);
		} else {
			if (
				['www', 'www_shop', 'www_safety'].includes(cur) &&
				!isValidURL(data[cur])
			) {
				acc.push(cur);
			}
		}
		return acc;
	}, []);

	return errors;
};

export const isRequiredKey = (key, data) => {
	const requiredFields = getRequiredFields(data);
	return requiredFields && requiredFields.includes(key);
};

export const isValidURL = (str) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
};

export const goToField = (field) => {
	const element = document.querySelector(`form [name='${field}']`);
	if (element) {
		element.focus();
	}
};
