import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';
import { isAdmin } from '../../Helpers/common';

import { useHistory } from 'react-router-dom';
import { apiClient } from '../../Helpers/apiCall';
import Loader from '../common/Loader';

import Timestamp from '../common/Timestamp';
import CustomTable from '../common/CustomTable';
import { HeaderAmount, HeaderAdd } from './HeaderAmount';
import {
	ICON_STATUS_LIVE_IMG,
	ICON_STATUS_HIDDEN_IMG,
} from '../../Helpers/icons';


export default function GroupsList({ dashboardView, tableConfig, preFilter }) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [filter, setFilter] = useState();
	var history = useHistory();

	const onRowClick = (row) => {
		history.push('/admin/group/' + row.original.id);
	};

	const { groups: dataSource } = state;
	const { items } = dataSource;

	const preCustomFilter = preFilter
		? preFilter
		: () => {
				return true;
		  };

	const processedRows = items
		.map((item) => {
			const newItem = { ...item };
			return newItem;
		})
		.filter(preCustomFilter)
		.reverse();

	const data = React.useMemo(() => {
		if (processedRows) {
			if (dashboardView) {
				return processedRows.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			} else {
				return processedRows;
			}
		} else {
			return [];
		}
	}, [dashboardView, processedRows]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			// {
			// 	Header: 'Kuva',
			// 	accessor: 'thumb', // accessor is the "key" in the data
			// 	Cell: ({ value, row }) => {
			// 		if (!value) return '';

			// 		return (
			// 			<Link
			// 				to={{
			// 					pathname: '/admin/group/' + row.original.id,
			// 				}}
			// 			>
			// 				<img
			// 					src={config.apiUrl + '/file/' + value.id + '/preset=thumb'}
			// 					alt=" "
			// 					loading="lazy"
			// 					className="thumb mini"
			// 				/>
			// 			</Link>
			// 		);
			// 	},
			// },
			{
				Header: 'Nimi',
				accessor: 'name',
			},
			{
				Header: 'Muokattu',
				accessor: 'updated_at',
				Cell: ({ value }) => {
					return <Timestamp timestamp={value} pure={true} />;
				},
			},
			{
				Header: 'Julkaistu?',
				accessor: 'is_published',
				Cell: ({ value }) => {
					return value === 1 ? (
						<ICON_STATUS_LIVE_IMG />
					) : (
						<ICON_STATUS_HIDDEN_IMG />
					);
				},
			},
		],
		[]
	);

	const addGroup = () => {
		apiCall.ADD('groups', {}).then((response) => {
			apiCall.GET('groups');
			if (response?.status === 200) {
				history.push('/admin/group/' + response.data.data.id);
			}
		});
	};

	if (dataSource.loading === true || !dataSource.items) {
		return <Loader />;
	}

	return (
		<section className="common-list">
			<div className="common-list-header">
				<h1>Käyttäjäryhmät</h1>
				<GroupsHeader data={processedRows} add={addGroup} />
			</div>
			{!dashboardView && (
				<>
					<input
						placeholder="Etsi"
						name="etsi"
						type="text"
						value={filter || ''}
						onChange={(e) => setFilter(e.target.value)}
					/>
				</>
			)}
			{filter && filter !== '' && <h3>{`Tuloksia hakusanalla '${filter}'`}</h3>}
			<CustomTable
				data={data}
				columns={columns}
				onRowClick={onRowClick}
				filter={filter}
				hidePagination={dashboardView}
				config={tableConfig}
			/>
		</section>
	);
}

export const GroupsHeader = (props) => {
	const data = props.data ? props.data : [];
	// const isPublished = data.filter((i) => i.is_published === 1);

	const admins = data.filter((i) => isAdmin(i));
	const nonAdmins = data.filter((i) => !isAdmin(i));

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={data.length} />
				<HeaderAmount label="Ylläpitäjiä" value={admins.length} />
				<HeaderAmount label="Muita käyttäjiä" value={nonAdmins.length} />
			</div>
			<HeaderAdd add={props.add} />
		</>
	);
};
