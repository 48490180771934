/* eslint-disable eqeqeq */
// import { toast } from 'react-toastify';
// import { replaceOrAddById } from '../App/Helpers/store';

export const ackInitialState = {
	loading: false,
	items: [],
};

export const ackReducer = (state = ackInitialState, action) => {
	switch (action.type) {
		case 'ACK_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		case 'ACK_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'ACK_EDITED':
		case 'ACK_ERROR':
			return {
				...state,
				loading: false,
			};

		case 'ACK_SET_LOADING':
			let nitems = [...state.items];
			let nindex = nitems.findIndex((val) => {
				return val.id == action.id;
			});
			if (nindex > -1) {
				nitems[nindex].loading = true;
			}

			return {
				...state,
				items: nitems,
				loading: false,
			};

		case 'ACK_SAVE':
			return {
				...state,
				loading: true,
			};

		case 'ACK_SAVE_SUCCESS':
		case 'ACK_SAVE_FAIL':
			return {
				...state,
				// items: action.payload.data.data,
				loading: false,
			};

		// case 'ACK__GET':
		// 	const items = replaceOrAddById(
		// 		[...state.items],
		// 		action.payload.data.data
		// 	);

		// 	return {
		// 		...state,
		// 		items,
		// 		loading: false,
		// 	};

		default:
			return state;
	}
};
