// import { replaceOrAddById } from '../App/Helpers/store';
import { config } from '../App/config';

const localStorageKey = config.localStoragePrefix + 'common';

const defaultState = {
	env: false,
	anMinimized: false,
	isLoading: false,
	isUploading: false,
};

export const commonInitialState = (() => {
	let state = window.localStorage.getItem(localStorageKey)
			? {
				...defaultState,
				...JSON.parse(window.localStorage.getItem(localStorageKey)),
		  }		
		: defaultState;
	state.env = false;
	state.isLoading = false;
	state.isUploading = false;
	return state;
})();

export const commonReducer = (state = commonInitialState, action) => {
	var common = state;
	switch (action.type) {
		case 'FILES_UPLOADING':
			common = { ...state, isUploading: true };
			break;
		case 'FILES_UPLOADED':
		case 'FILES_FAILED':
			common = { ...state, isUploading: false };
			break;
		case 'TOGGLE_ADMIN_NAV':
			common = { ...state, anMinimized: !state.anMinimized };
			break;
		case 'INFO/WHICH-ENV_GET':
			common = { ...state, env: { ...action.payload.data.data } };
			break;
			

		default:
			return state;
	}

	window.localStorage.setItem(localStorageKey, JSON.stringify(common));

	return common;
};
