import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation, Link, NavLink } from 'react-router-dom';

import { useStore } from '../../store/useStore';
import { SVG_LOGO, SVG_BACK } from '../Helpers/icons';
// import Search from '../Views/Search';

const AppHeader = ({ searchFilter, setSearchFilter}) => {
	var history = useHistory();

	const { state } = useStore();
	const inputRef = useRef(null);
	
	const handleOnChange = (value) => {
		setSearchFilter(value);
		history.push('/search');
	}
	
	useEffect(() => {
		const handleKeyDown = (event) => {
			// Check if CMD/CTRL key and 'K' key are pressed simultaneously
			if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
				event.preventDefault();
				inputRef.current.focus();
				window.scrollTo(0, 0);
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const { user } = state;

	const location = useLocation();
	const disallowedRoutes = ['/weekly', '/feed'];
	var backLocation = location.pathname.split("/").slice(0, -1).join("/");
	if (!backLocation || disallowedRoutes.includes(backLocation)) {
		backLocation = '/';
	}
	
	const showHeaderIcons = user.loggedIn && !user.data.needs_onboarding;

	return (
		<>
			<header
				className={`c-header container ${
					showHeaderIcons ? 'logged-in' : 'logged-out'
				}`}
			>
				{user.loggedIn && (
					<Link
						className='logo'
						to={backLocation}
						onClick={() => {
							if (typeof setSearchFilter == 'function') {
								setSearchFilter('');
							};
							if (location.pathname === '/') { history.go(0); }
						}}
					>
						{backLocation === '/' && <SVG_LOGO />}
						{backLocation !== '/' && <SVG_BACK />}
					</Link>
				)}

				{user.loggedIn && (
					<div className='search-input-wrapper'>
						<input
							ref={inputRef}
							className='search-input'
							type='text'
							onChange={(e) => handleOnChange(e.target.value)}
							value={searchFilter}
							placeholder='Etsi'
						/>
					</div>
				)}

				{user.loggedIn && (
					<div className='cog-menu'>
						<NavLink to={'/profile'} activeClassName='active'>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g id='Union'>
									<path
										d='M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z'
										fill='#FE7D62'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M13.9184 1.36596C14.7086 -0.455319 17.2914 -0.45532 18.0816 1.36596C18.663 2.70607 20.3408 3.15563 21.5143 2.28576C23.1092 1.10356 25.3461 2.39499 25.1197 4.36734C24.9532 5.8186 26.1814 7.04683 27.6327 6.88028C29.605 6.65393 30.8964 8.89075 29.7142 10.4857C28.8444 11.6592 29.2939 13.337 30.634 13.9184C32.4553 14.7086 32.4553 17.2914 30.634 18.0816C29.2939 18.663 28.8444 20.3408 29.7142 21.5143C30.8964 23.1092 29.605 25.3461 27.6327 25.1197C26.1814 24.9532 24.9532 26.1814 25.1197 27.6327C25.3461 29.605 23.1092 30.8964 21.5143 29.7142C20.3408 28.8444 18.663 29.2939 18.0816 30.634C17.2914 32.4553 14.7086 32.4553 13.9184 30.634C13.337 29.2939 11.6592 28.8444 10.4857 29.7142C8.89075 30.8964 6.65393 29.605 6.88028 27.6327C7.04683 26.1814 5.8186 24.9532 4.36734 25.1197C2.39499 25.3461 1.10356 23.1092 2.28576 21.5143C3.15563 20.3408 2.70607 18.663 1.36596 18.0816C-0.45532 17.2914 -0.455319 14.7086 1.36596 13.9184C2.70607 13.337 3.15563 11.6592 2.28576 10.4857C1.10356 8.89075 2.39499 6.65393 4.36734 6.88028C5.8186 7.04683 7.04683 5.8186 6.88028 4.36734C6.65393 2.39499 8.89075 1.10356 10.4857 2.28576C11.6592 3.15563 13.337 2.70607 13.9184 1.36596ZM24.6667 16C24.6667 20.7865 20.7865 24.6667 16 24.6667C11.2135 24.6667 7.33333 20.7865 7.33333 16C7.33333 11.2135 11.2135 7.33333 16 7.33333C20.7865 7.33333 24.6667 11.2135 24.6667 16Z'
										fill='#FE7D62'
									/>
								</g>
							</svg>
						</NavLink>

						{/* <NavLink to={'/profile'} activeClassName="active">
							<span className="icon">
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10 0a6.25 6.25 0 110 12.5A6.25 6.25 0 0110 0zm7.16 13.04l-2.786-.697c-2.927 2.106-6.474 1.636-8.748 0l-2.785.697A3.75 3.75 0 000 16.678v1.447C0 19.161.84 20 1.875 20h16.25C19.161 20 20 19.16 20 18.125v-1.447a3.75 3.75 0 00-2.84-3.638z"
										fill="#FFF"
										fillRule="nonzero"
									/>
								</svg>
							</span>
						</NavLink> */}
						{/* <button onClick={logoutAction}>Log out</button> */}
					</div>
				)}
			</header>
			{/* <Search isOpen={searchOpen} toggleSearch={toggleSearch} /> */}
		</>
	);
};
export default AppHeader;
