import React, { useState } from 'react';
import { apiClient } from '../Helpers/apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import MainTitle from '../Components/common/MainTitle';
// import Navigation from '../Components/common/Navigation';

// import { useHistory } from 'react-router-dom';

export default function FeedbackForm() {
	// var history = useHistory();
	const { state, dispatch } = useStore();
	const { feedback } = state;
	// const { form } = feedback;
	const [formState, setFormState] = useState('feedback');

	const apiCall = apiClient(state, dispatch);

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: 'FEEDBACKFORM_INPUT', key: name, value: value }, [
			dispatch,
		]);
	};

	const handleSwitch = (event) => {
		const { name } = event.target;
		const bool = !(feedback?.form[name] ?? false) === true ? 1 : 0;
		dispatch({ type: 'FEEDBACKFORM_INPUT', key: name, value: bool }, [
			dispatch,
		]);
	};

	const resetForm = () => {
		dispatch({ type: 'FEEDBACKFORM_RESET', value: {} });
		setFormState('feedback');
	};

	const onSubmit = (e) => {
		dispatch({ type: 'FEEDBACKFORM' });
		e.preventDefault();
		e.stopPropagation();

		apiCall
			.POSTRAW('feedback', 'feedbackform', feedback.form)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'FEEDBACKFORM_SUCCESS', payload: response });
					setFormState('thanks');
				} else {
					dispatch({ type: 'FEEDBACKFORM_FAIL', payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: 'FEEDBACKFORM_FAIL' });
			});
	};

	return (
		<div className='feedbackContainer'>
			<div className='feedbackForm'>
				<MainTitle>Feedback</MainTitle>

				<div className='split-container'>
					<div className='content'>
						<p>
							Feeling anxious, work is not going well because…? You have a
							suggestion for improvement, but you don’t know who to turn to.
						</p>
						<p>
							This is the solution to the problem: a classic anonymous feedback
							box! A place where we gather, listen and, above all, solve small
							and bigger everyday challenges.
						</p>
						{/* <p>
							<strong>The principle of operation is this:</strong>
						</p> */}
						<p>
							1. You write anonymously about a problem or challenge you
							perceive, or share your suggestions for improvement to make
							everyday life smoother.
						</p>
						<p>
							2. HR, together with together with those whom the matter concerns,
							seeks a solution to the problem and puts into practice the
							initiatives found to be feasible. {' '}
							<strong>
								NOTE! Only reported challenges will be solved. The word is free
								- go for it!
							</strong>
						</p>
						<p>You can also send feedback about the Royal Backstage here or in #backstage channel over at Slack.</p>
						{/* <p>
							In the fight against the (useless) everyday drama, HR & Team
							Leaders
						</p> */}
					</div>

					{formState !== 'feedback' && (
						<div className='c-form'>
							<p></p>
							<h4>Thanks for the feedback!</h4>
							<p>
								<button className='c-button' onClick={resetForm}>
									Send more feedback
								</button>
							</p>
						</div>
					)}
					{formState === 'feedback' && (
						<form className='c-form' onSubmit={onSubmit}>
							<label className='label-input'>
								<h3>Your message:</h3>
								<textarea
									name='message'
									value={feedback?.form?.message ?? ''}
									onChange={(e) => {
										changeInputValue(e);
									}}
								/>
							</label>
							<label className='c-checkbox'>
								<input
									type='checkbox'
									name='public'
									value='1'
									onChange={(e) => {
										handleSwitch(e);
									}}
								/>
								<span>
									<span />I want to give my feedback under my name
								</span>
							</label>

							<div className='buttons'>
								{feedback.loading && <Loader></Loader>}
								{!feedback.loading && (
									<div>
										<button className='c-button' type='submit'>
											Submit
										</button>
									</div>
								)}
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
}
