import React from "react";
import Loader from '../Components/common/Loader';
import { SVG_LOGO } from '../Helpers/icons';


const Splash = () => {
	return (
		<div className="c-splash">
			<SVG_LOGO />
			<Loader />
		</div>
	);
};

export default Splash;