import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { Analytics } from '@vercel/analytics/dist/react';

import App from './App';
import { config } from './config';

import Loader from './Components/common/Loader';
import { BrowserRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		console.error(error, info);
		// logErrorToMyService(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div
					className='errorFrame'
					onClick={() => {
						window.location.href = '/';
					}}
				>
					<h3>Uh-oh, something went wrong</h3>
					<p>
						Try loading the page again and using another browser or device. If
						the problem persists,{' '}
						<a href={config.slack.supportChannel} target='_blank' rel="noreferrer">
							send us a message on Slack (#backstage)
						</a>
					</p>
					<button className='c-button'>OK</button>
				</div>
			);
		}
		return this.props.children;
	}
}

const AppContainer = () => {
	return (
		<ErrorBoundary>
			<BrowserRouter
				onUpdate={() => {
					window.scrollTo(0, 0);
				}}
			>
				<Suspense fallback={<Loader />}>
					<App />
				</Suspense>
			</BrowserRouter>
			<ToastContainer draggable />
			<Analytics />
		</ErrorBoundary>
	);
};

// const ErrorTest = () => {
// 	throw new Error('I crashed!');
// };

export default AppContainer;
