import React, { useEffect } from 'react';

// import { useHistory } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import axios from 'axios';
import { config } from '../config';
// import Loader from '../Components/common/Loader';
import Ticker from '../Components/Ticker';

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { animated, useTrail } from 'react-spring';
import MainTitle from '../Components/common/MainTitle';

export const skiplogin = async (dispatch) => {
	const res = await axios({
		url: config.apiUrl + '/auth/logintest',
		method: 'post',
	});

	if (res.status === 200) {
		dispatch({ type: 'LOGIN_SUCCESS', payload: res });
		// history.push('/');
	} else {
		dispatch({ type: 'LOGIN_FAIL', payload: res });
	}
};

export default function LoginPage() {
	// const { dispatch } = useContext(GlobalStore);
	const { state, dispatch } = useStore();

	const responseGoogle = async (googleResponse) => {
		const res = await axios({
			url: config.apiUrl + '/auth/gsi',
			method: 'post',
			data: googleResponse,
		}).catch((error) => error.response);

		if (res.status === 200) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: res });
		} else {
			dispatch({ type: 'LOGIN_FAIL', payload: res });
		}
	};

	useEffect(() => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let loginAction = params.get('logintest');

		if (loginAction) {
			skiplogin(dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [springs, set] = useTrail(4, (index) => ({
		transform: `translateY(-10px)`,
		opacity: 0,
		config: {
			mass: 10,
			tension: 100,
		},
	}));

	// Update springs with new props
	set({ opacity: 1, transform: `translateY(0)` });

	const tickerItems = [
		'🌞 Good day, dear Friend! 🌞',
		'Login to see more',
		'🌞 Good day, dear Friend! 🌞',
		'Login to see more',
		'🌞 Good day, dear Friend! 🌞',
		'Login to see more',
		'🌞 Good day, dear Friend! 🌞',
		'Login to see more',
	];

	return (
		<>
			<div className="page login-page">
				<animated.div style={springs[0]}>
					<MainTitle className={"center"}>Royal Backstage</MainTitle>
				</animated.div>

				<animated.div style={springs[1]}>
					<Ticker items={tickerItems} />
				</animated.div>
				<animated.div style={springs[2]}>
					<div className="login-btn-wrapper">
						<GoogleOAuthProvider clientId="177889218619-1vccf8e7vhqoo16ldjp7da023bsjg5n7.apps.googleusercontent.com">
							<GoogleLogin
								text="continue_with"
								theme="filled_blue"
								size="large"
								logo_alignment="left"
								onSuccess={responseGoogle}
								onFailure={responseGoogle}
								useOneTap={true}
								use_fedcm_for_prompt={true}
								hosted_domain={config.googleClient.hd}
								auto_select={true}
								itp_support={true}
							/>
						</GoogleOAuthProvider>
					</div>
				</animated.div>

				<animated.div style={springs[3]}>
					{state?.user?.loginhasFailed && (
						<div className="login-failed-wrapper">
							<img
								src="https://64.media.tumblr.com/b0808ae38eb6dd00e9983fdcace6f0a1/cd8ec3336f9fe212-5f/s400x600/5b229289bd81b9144f717b5b514e9b27bebb179f.gifv"
								alt=""
							/>
							<p>
								<strong>Login not working? </strong>
								<br />
								Try closing all instances of this app and reloading the page. If
								problems persist, please contact HR to get access.
							</p>
							<p>
								<button
									className="c-button"
									onClick={() => window.location.reload()}
								>
									Reload page
								</button>
							</p>
						</div>
					)}
				</animated.div>
			</div>
		</>
	);
}
