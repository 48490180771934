/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import { apiClient } from '../Helpers/apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
// import Navigation from '../Components/common/Navigation';
// import BackButton from '../Components/common/BackButton';

// import { config } from "../config";
import { Link, NavLink } from 'react-router-dom';
import MainTitle from '../Components/common/MainTitle';

const baseUrl = '/manual';

export default function Manual({ pageId }) {
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);

	const tmpPageId = !!pageId ? pageId : 'welcome';

	const { pages } = state;

	const manualPages = [...(pages?.items ?? [])]
		.sort((a, b) => {
			if (a.id == 2111) return -1;
			if (b.id == 2111) return 1;

			if (a.title.rendered < b.title.rendered) {
				return -1;
			}
			if (a.title.rendered > b.title.rendered) {
				return 1;
			}
			return 0;
		});

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isMounted = true;

		// fetchX('pages');

		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const page = manualPages.find((i) => i.slug == tmpPageId);

	// Redirect to first page:
	/* if (!pageId && !pages.loading) {
		// let tmpPage = manualPages.find(i => i.id == 2111) ?? manualPages[0];
		let tmpPage = manualPages[0];
		return <Redirect to={`${baseUrl}/${tmpPage.slug}`} />;
	} */

	// if (!pageId && !pages.loading) {
	// 	// let tmpPage = manualPages.find(i => i.id == 2111) ?? manualPages[0];
	// 	let tmpPage = manualPages[0];
	// 	return <Redirect to={`${baseUrl}/${tmpPage.slug}`} />;
	// }


	return (
		<div className="c-manual">
			<MainTitle>Toolkit</MainTitle>

			<div className="">
				{pages.loading && <Loader className=""></Loader>}
				{!pages.loading && (
					<section className="wp-nav">
						{/* <BackButton /> */}

						<div className="">
							<SubPages
								pageId={0}
								tmpPageId={tmpPageId}
								isLoading={pages.loading}
								pages={manualPages}
							/>
						</div>
					</section>
				)}
				{!pages.loading && tmpPageId && (
					<PageTemplate page={page} pages={manualPages} />
				)}
			</div>
		</div>
	);
}

const SubPages = ({ isLoading, pages, pageId, level, tmpPageId }) => {

	if (!pageId) pageId = 0;
	if (!level) level = 0;

	const filteredPages = pages && pages.filter((i) => (i.parent == pageId || (pageId == 0 && !i.parent) ) );
	// const filteredPages = pages && pages.filter((i) => (i.parent == pageId ) );

	return (
		filteredPages.length > 0 ?
		<ul className={` ${level > 0 ? 'sub sub-'+level : ''}`}>
			{filteredPages.map((page) => {
				return (
					<li key={page.id}>
						<NavLink
							activeClassName='active'
							className={({ isActive }) => {
								return page.slug === tmpPageId || isActive ? 'active' : '';
							}}
							to={`${baseUrl}/${page.slug}`}
							dangerouslySetInnerHTML={{ __html: page.title.rendered }}
						/>
						<SubPages
							pageId={page.id}
							isLoading={isLoading}
							pages={pages}
							level={level + 1}
						/>
					</li>
				);
			})}
			{isLoading && <Loader className="dark" />}
			</ul>
			: <></>
	);
};

const PageTemplate = ({ page, pages }) => {
	// const history = useHistory();

	// const { state, dispatch } = useStore();
	// const { pages } = state;

	if (!page) {
		return <Loader className=""></Loader>;
	}

	// const pageIndex = pages.findIndex((i) => i.id == page.id);

	// const prevArticle = pages.find((i) => i.id < page.id);
	// const nextArticle = pages.find((i) => i.id > page.id);
	// const prevArticle = pages[pageIndex - 1];
	// const nextArticle = pages[pageIndex + 1];

	// let backUrl = `${baseUrl}`;
	const parent = page.parent != 0 ? pages.find(i => page.parent == i.id) : false;

	// if (parent) backUrl = `${backUrl}/${parent.slug}`;

	return (
		<section id={page.slug} className="c-page article wp-page">
			<h1 className='article-title'>
				{parent ? (
					<>
						<Link
							to={parent.slug}
							dangerouslySetInnerHTML={{ __html: parent.title.rendered }}
						/>
						<span> / </span>
					</>
				) : (
					''
				)}
				<span
					className="title"
					dangerouslySetInnerHTML={{ __html: page.title.rendered }}
				/>
			</h1>
			{/* <BackButton onClick={() => history.push(backUrl)} /> */}
			<div className="c-article">
				<div
					dangerouslySetInnerHTML={{ __html: page?.content?.rendered ?? '' }}
				></div>
			</div>
			<div className="fixer negateTopMargin">
				<SubPages pageId={page.id} isLoading={!page} pages={pages} />
			</div>
			{/* <div className="c-article-nav">
				{prevArticle && (
					<Link className="prev" to={`/manual/${prevArticle.slug}`}>
						{prevArticle.title.rendered}
					</Link>
				)}
				{nextArticle && (
					<Link className="next" to={`/manual/${nextArticle.slug}`}>
						{nextArticle.title.rendered}
					</Link>
				)}
			</div> */}
		</section>
	);
};

// const PageTemplate = (props) => {
// 	const { state, dispatch } = useStore();
// 	const apiCall = apiClient(state, dispatch);

// 	const data = state.pages.items.find((page) => page.slug === props.slug);

// 	if (!data) {
// 		return <Loader className="dark"></Loader>;
// 	}

// 	// const imageUrl = data.hero
// 	// 	? data.hero.publicUrl + '/preset=hero'
// 	// 	: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

// 	// const html = (data?.content_json ?? []).find((i) => i.id == 'test');

// 	// const modules = data?.content_json ?? [];

// 	return (
// 		<section id={page}>

// 		</section>
// 	);
// };
