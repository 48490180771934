import React, { useState, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../Helpers/apiCall';
import { NavTab } from 'react-router-tabs';

import {
	CustomInput,
	CustomSwitch,
	CustomSelect,
	CustomPictureSelector,
	CustomCheckbox,
	CustomCheckboxes,
	createHandlers,
} from '../../Components/common/CustomInput';

import { USER_ROLES } from '../../Helpers/common';

import Loader from '../../Components/common/Loader';
import PhoneInput from 'react-phone-number-input';

export default function User(props) {
	const { state, dispatch } = useStore();
	// const { user: currentUser } = state;
	const isUploading = state.common.isUploading;
	const apiCall = apiClient(state, dispatch);
	const [data, setData] = useState({});
	const [redirect, setRedirect] = useState(false);
	const formRef = useRef();
	const fileSelectorRef = useRef();
	const { id, match } = props;

	// const ENDPOINT_SINGULAR = 'user';
	const ENDPOINT_PLURAL = 'users';

	useEffect(() => {
		let isMounted = true;
		if (id) {
			apiCall.GET_USER(id).then((response) => {
				if (response.status === 200) {
					if (isMounted) {
						setData({
							...response.data.data,
						});
					}
				}
			});
		}
		return () => {
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const { handleOnChangeForCheckBoxes, handleOnChange, handleSwitch, sendFile } =
		createHandlers(data, setData, apiCall, state);

	const customErrors = [];

	const onSubmit = (e) => {
		const form = formRef.current;
		const isValid = form && form.checkValidity();
		const isCustomValid = customErrors.length === 0;

		if (id) {
			if (isValid) {
				e.preventDefault();
				if (isCustomValid) {
					var saveData = { ...data };
					apiCall.EDIT(ENDPOINT_PLURAL, id, saveData).then((response) => {
						if (response.status === 200) {
							apiCall.GET_USER(data.id);
						}
					});
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		}
	};

	const remove = () => {
		if (window.confirm('Haluatko varmasti poistaa tämän?')) {
			apiCall.DELETE(ENDPOINT_PLURAL, data.id).then(() => {
				apiCall.GET(ENDPOINT_PLURAL);
				setRedirect(true);
			});
		}
	};

	// const submitForgotPassword = () => {
	// 	dispatch({ type: 'FORGOT_PASSWORD' });

	// 	axios({
	// 		url: config.apiUrl + '/auth/forgot-password',
	// 		method: 'post',
	// 		data: {
	// 			data: {
	// 				email: data.email,
	// 			},
	// 		},
	// 	})
	// 		.then((response) => {
	// 			if (response.status === 200) {
	// 				dispatch({ type: 'FORGOT_PASSWORD_SUCCESS', payload: response });
	// 				// setFormState('forgotPasswordSubmitted');
	// 			} else {
	// 				dispatch({ type: 'FORGOT_PASSWORD_FAIL', payload: response });
	// 			}
	// 		})
	// 		.catch((Error) => {
	// 			dispatch({ type: 'FORGOT_PASSWORD_FAIL' });
	// 		});
	// };

	const groupsOptions = (state?.groups?.items ?? []).map((i) => {
		return {
			id: i.id,
			name: i.name,
		};
	});
	const podsOptions = (state?.pods?.items ?? []).map((i) => {
		return {
			id: i.id,
			name: i.name,
		};
	});

	if (redirect === true) {
		return <Redirect to={`/admin/${ENDPOINT_PLURAL}`} />;
	}

	if ((id && !data.id) || state.users.loading || data.loading) {
		return <Loader className="dark"></Loader>;
	}

	return (
		<div className='company-form'>
			<header>
				<div className='inner'>
					<div className='top'>
						<h1>
							<span className='category'>
								<Link to={'/admin/users'} className=''>
									Käyttäjä /
								</Link>
								#{data.id}
							</span>
							{data.first_name === '' || data.first_name === null
								? 'Nimeämätön kohde '
								: `${data.first_name} ${data.last_name}`}
						</h1>
					</div>
				</div>
			</header>
			<div className='header-bottom'>
				<nav>
					<ul className='max-width'>
						<li>
							<NavTab to={`${match.url}/info`}>Yleisinfo</NavTab>
						</li>
					</ul>
				</nav>
			</div>

			<Switch>
				<Route
					exact
					path={`${match.path}`}
					render={() => <Redirect replace to={`${match.url}/info`} />}
				/>

				<Route exact path={`${match.url}/info`}>
					<section>
						<form onSubmit={onSubmit} ref={formRef}>
							<fieldset id='tila'>
								<legend>Tila</legend>
								<div className='input-wrapper'>
									<CustomSwitch
										label={'Julkaistu?'}
										field={'is_published'}
										handleOnChange={handleSwitch}
										data={data}
									/>
								</div>
							</fieldset>
							<fieldset id='perustiedot'>
								<legend>Perustiedot:</legend>
								<div className={`grid-flex two`}>
									<div>
										<div className='input-wrapper'>
											<CustomInput
												label={'Etunimi'}
												field={'first_name'}
												handleOnChange={handleOnChange}
												data={data}
											/>
										</div>
										<div className='input-wrapper'>
											<CustomInput
												label={'Sukunimi'}
												field={'last_name'}
												handleOnChange={handleOnChange}
												data={data}
											/>
										</div>
										<div className='input-wrapper'>
											<label>
												<span className='label'>Puhelinnumero</span>
												<PhoneInput
													international={true}
													// country='FI'
													defaultCountry={'FI'}
													value={data.phone}
													withCountryCallingCode={true}
													onChange={(val) => {
														handleOnChange({
															target: { name: 'phone', value: val },
														});
													}}
												/>
												<span className='dim'>({data.phone})</span>
											</label>
										</div>
										<div className='input-wrapper'>
											<span className='label'>
												<CustomCheckbox
													subLabel={'Piilota puhelinnumero'}
													field={'is_private'}
													handleOnChange={handleSwitch}
													data={data}
												/>
											</span>
										</div>
									</div>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<div className='input-wrapper'>
											<label>
												<span className='label'>Kuva</span>
											</label>
											<CustomPictureSelector
												data={data}
												field={'thumb_id'}
												handleOnChange={sendFile}
												fileSelectorRef={fileSelectorRef}
												autocomplete={'photo'}
												isRequired={false}
												isUploading={isUploading}
											/>
										</div>
									</div>
								</div>
							</fieldset>
							<fieldset>
								<div className='input-wrapper'>
									<CustomInput
										label={'Titteli'}
										field={'title'}
										handleOnChange={handleOnChange}
										data={data}
									/>
								</div>

								<div className='input-wrapper'>
									<CustomInput
										label={'Sähköposti'}
										field={'email'}
										handleOnChange={handleOnChange}
										data={data}
									/>
								</div>

								<div className='input-wrapper'>
									<CustomSelect
										label={'Käyttäjätyyppi'}
										field={'role_id'}
										handleOnChange={handleOnChange}
										data={{ role_id: data?.role?.id ?? 1 }}
										options={USER_ROLES}
									/>
								</div>
								<div className='input-wrapper'>
									{/* TODO: User can belong to multiple groups, how to style? */}
									{/* <CustomSelect
										label={'Käyttäjäryhmä'}
										field={'group_id'}
										handleOnChange={handleOnChange}
										data={data}
										options={groupsOptions}
									/> */}
									<CustomCheckboxes
										label={'Käyttäjäryhmät'}
										subLabel={'Valitse vähintään yksi'}
										field={'group'}
										handleOnChange={handleOnChangeForCheckBoxes}
										data={data}
										options={groupsOptions}
									/>
								</div>
								<div className='input-wrapper'>
									<CustomCheckboxes
										label={'Podlings'}
										subLabel={'---'}
										field={'pod'}
										handleOnChange={handleOnChangeForCheckBoxes}
										data={data}
										options={podsOptions}
									/>
								</div>
							</fieldset>
						</form>

						{/* <fieldset>
							<legend>Käyttäjän kategoria</legend>							
						</fieldset> */}
						<fieldset>
							<legend>Notifikaatiotiedot</legend>
							<textarea
								disabled={true}
								value={data.notification_settings ?? ''}
							/>
							<Link to={`/admin/debug/${data.notification_settings}`}>
								Testaa notifikaation lähetystä &raquo;
							</Link>
							{/* <textarea
								disabled={true}
								value={data.fcmtoken ?? ''}
							/> */}
							<br/>
							<br/>
							{data.fcmtoken.length > 0 && (
								<table>
									<thead>
										<tr>
											<td>Token</td>
											<td>Timestamp</td>
										</tr>
									</thead>
									<tbody>
										{data.fcmtoken.map((i) => (
											<tr key={i.id}>
												<td>
													<Link to={`/admin/debug/${i.token}`}>
														{`${i.token.slice(0, 16)}...`}
													</Link>
												</td>
												<td>
													{i.user_agent}
												</td>
												<td>{i.created_at}</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</fieldset>
						{/* <fieldset>
							<legend>Muut tiedot</legend>
						</fieldset> */}
						<fieldset>
							<legend>Toiminnot</legend>
							{/*{currentUser.forgotPasswordLoading && <Loader></Loader>}
							{data.id && !currentUser.forgotPasswordLoading && (
								<button
									type="button"
									className="button"
									onClick={submitForgotPassword}
								>
									Lähetä kirjautumislinkki
								</button>
							)}
							*/}
							{data.id && (
								<button
									type='button'
									className='button delete'
									onClick={remove}
								>
									Poista?
								</button>
							)}
						</fieldset>
					</section>
				</Route>
			</Switch>

			<div className='submit-holder'>
				<button
					type='submit'
					className='button'
					form='place-form'
					onClick={onSubmit}
				>
					{data.loading && <Loader></Loader>}
					{data.loading ? '' : data.id ? 'Tallenna muutokset' : 'Lisää kohde'}
				</button>
			</div>
		</div>
	);
}
